@import "../variables";

#contactUs {
    .accordion-header {
        @include transition($nav-link-transition);
        cursor: pointer;
        &:hover {
            color: $premier;
            .accordion-arrow:after{
                background: $premier;
            }
        }
        &.active{
            .accordion-arrow:after{
                transform: rotate(-180deg);
            }
        }
    }
    .accordion-arrow:after{
        content: '';
        display: block;
        background: $primary;
        width: 1.5rem;
        height: 1.5rem;
        margin: auto;
        -webkit-mask-image: url("images/accordion-icon-dust.svg");
        mask-image: url("images/accordion-icon-dust.svg");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: top;
        mask-position: top;
        -webkit-mask-size: contain;
        mask-size: contain;
        @include transition($nav-link-transition);
    }
    
}

.icon-pin {
    display: block;
    width: $arrow-size;
    height: $arrow-size * 2;
    transition: background-color 0.3s ease-in-out;
    -webkit-mask-image: url("images/pin.svg");
    mask-image: url("images/pin.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background-color: $primary;
    &:hover {
        background-color: $premier;
    }
}

#modal-contact{
    .icon-pin{
        display: inline-block;
        height: $arrow-size * 1.2;
        background-color: $secondary;
    &:hover {
        background-color: $secondary;
    }
    }
    .btn-close{
        position: absolute;
        right:$spacer;
        top:$spacer;
        background-color: $secondary;
    }
    .navbar-contact{
        span:before{
            display: none!important;
        }
    }
}

.invalid-feedback {
    padding-left: $input-padding-x;   
    &.invalid-pattern{
        display: none!important;
    }
}

.invalid-pattern{
    .invalid-feedback{
        display: none!important;
    }
    .invalid-pattern{
        display: block!important;
    }
}

.text-dust {
    .contact-disclaimer {
        a {
            color: $secondary!important;
        }
        p:last-child {
            margin-bottom: 0!important;
        }
    }
    .invalid-feedback {
        color: $secondary!important;
    }
}
