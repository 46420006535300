@import "../../variables";


.doughnut-chart-wrapper {
    max-width: 60%;
    margin:0 auto;
    @include media-breakpoint-down(md){
        max-width: 80%;
    }
    position: relative;
    .chart-legend{
        ul{
            li{
                transform: none!important;
                &:hover{
                    cursor: default;
                }
            }
        }
    }
    @include media-breakpoint-up(sm){
        .chart-legend:not(.chart-legend-below):not(.chart-legend-below-block){
            text-align: left;
            padding-left: 1%;
            width:33%;
            position: absolute;
            right:0;
            height: 100%;
            top:0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            ul{
                text-align: left;
                li{
                    display: block;
                    padding-left: 20px;
                    transform: none!important;
                    margin: 0;
                    position: relative;
                    .point{
                        position: absolute;
                        top:0;
                        left: 0;
                        +span{
                            margin-left: $spacer/4;
                        }
                    }
                    &:hover{
                        cursor: default;
                    }
                }
            }
        }
        .chart:not(.chart-legend-below):not(.chart-legend-below-block) canvas{
            margin-right:33%;
        }
    }
    .chart.chart-legend-below, .chart.chart-legend-below-block {
        canvas{
        margin-right:16.5%;
        margin-left:16.5%;
        }
    }
}

.chart-wrapper{
    margin-top:1em;
    margin-bottom:1em;
    overflow: auto;
    width: 100%;
    // &.is-visible{
    //     .chart-legend{
    //         ul{
    //             @for $i from 0 to 50 {
    //                 li:nth-child(#{$i}) { animation: fadeIn 1s ease-out forwards; animation-delay: $i * 0.25s; }
    //             }
    //         }
    //     }
    // }
    &:not(.doughnut-chart-wrapper) .chart{
        min-width: 640px;
    }
}


.chart-legend{
    text-align: center;
    &.chart-legend-below-block{
        ul {
            li, span:not(.point){
            display: block;}
            .point{
                margin-right:0;
            }
        }
    }
    ul{
        //display: inline-block;
        margin: $spacer 0;
        padding: 0;
        list-style: none;
        text-align: center;
        span{
            display: inline-block;
            vertical-align: middle;
           
        }
        .point{
            width:20px;
            height: 20px;
            border-radius: 100%;
            margin-right: $spacer/4;
        }
        li{
            //opacity: 0;
            margin: $spacer/4 $spacer/2;
            display: inline-block;
            font-size: $font-size-sm;
            transition: transform 500ms ease;
            transform: translateZ(0);
            -webkit-font-smoothing: subpixel-antialiased;
            backface-visibility: hidden;
            color:rgb(34, 56, 79);
            &:hover{
                transform: scale(1.05);
                cursor: pointer;
            }
        }
    }
}

.point-reset{
    margin: $spacer $spacer/2 0;
    display: none;
    font-size: $font-size-sm;
    transition: transform 500ms ease;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    color:rgb(34, 56, 79);
    opacity: 0;
    &.active{
        display: inline-block;
        animation: fadeIn 1s ease-out forwards;
        +ul{
            margin-top: 0!important;
        }
    }
    &:hover{
        transform: scale(1.05);
        cursor: pointer;
    }
}


.chart-export{
    position: relative;
    display: inline-block;
    margin: 1rem 0;
    float: right;
    + br{
        clear: both;
    }
    button{
        &:hover,&:active,&:focus{
            background-color: $premier;
            border-color: $premier;
        }
    }
    ul{
        
        display: none;
        opacity: 0;
        list-style: none;
        background:$secondary;
        @extend .shadow;
        @extend .rounded;
        padding:$spacer/2 0;
        margin-top: $spacer/2;
        position: absolute;
        left: 0;
        width: 100%;
        transition: transform 500ms ease;
        font-size: $font-size-sm;
        a{
            padding:$spacer/4 $spacer/2;
            display: block;
            text-decoration: none;
            text-align: center;
        }
        &.active{
            display: block;
            opacity: 1;
        }
    }
}


.chartjs-half{
    width: 50%;
    display: inline-block;
    margin-left: -2px;
    @include media-breakpoint-down(md){
        width: 100%;
    }
    @include media-breakpoint-up(md){
        .doughnut-chart-wrapper{
            max-width: 100%;
        }
    }
    .chart-legend ul li{
        font-size: $font-size-sm*0.9!important;
    }
}
