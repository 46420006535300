@import "../../variables";


.carousel {
    min-height: 18rem;
    @include media-breakpoint-down(md){
        min-height: 40rem;
    }
    @include media-breakpoint-down(sm){
        min-height: 33rem;
    }

    &.carousel-quotes {
        min-height: 15rem;
        @include media-breakpoint-down(md){
            min-height: 18rem;
        }
    }
}

.carousel-indicators {
    button {
        background-color: $primary !important;
        border-radius: 50%;
        &.active {
            background-color: $premier !important;
        }
    }
}

.swiper, .swiper-stats, .swiper-highlights, .swiper-quotes, .swiper-awards {
    height: auto;
    img {
        max-height: 300px;
    }
}

.swiper-gallery{
    img {
        max-height: none !important;
    }
}

.swiper-awards{
    img {
        max-height: none!important;
        opacity:1;
    }
}

.swiper-home {
    img {
        max-height: 250px !important;
    }
    &-award {
        max-width: 19.75rem;
        &-image {
            aspect-ratio: 1;
            object-fit: contain;
        }
    }
}

.swiper-awards{
    img {
        max-height: none!important;
        opacity:1;
    }
}

.swiper-quotes {
    h2 {
        font-size: 2.2rem;
        @include media-breakpoint-down(sm){
            font-size: 1.8rem;
        }
    }
}

.swiper-stats, .swiper-quotes, .swiper-gallery, .swiper-careers-awards, .swiper-awards {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-pagination-bullet-active {
    background-color: $premier !important;
}

.swiper-pagination-bullet {
    background-color: $primary;
    opacity: 1;
}

.bg-primary{
    .swiper-pagination-bullet {
        background-color: $secondary;
    }
}

.swiper-button-next,.swiper-button-prev{
    transition: all 0.3s ease-in-out;
    &:after{
        color:$primary;
        font-size: $font-size-base;
    }
}
.swiper-button-next{
    right:-$spacer*0.4;
    &:hover{
        right:-$spacer*0.75;
    }
}
.swiper-button-prev{
    left:-$spacer*0.4;
    &:hover{
        left:-$spacer*0.75;
    }
}
.swiper-nav-wrapper{
    position: relative;
}