@import "../../variables";

.form-select {
    padding: $input-padding-x;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fff5e6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    &.bg-secondary,&.bg-tint {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2318263a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    }
}

.form-floating>.bg-tint+label, .form-floating>.bg-secondary+label{
    color:$input-placeholder-color;
    opacity: 1!important;
}
.form-floating>label{
    color:$secondary;
    opacity: 1!important;
}

textarea{
    height: 105px!important;
}


.placeholder-50::placeholder{
    opacity: 0.5!important;
}

.range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: $rangeTrack;
    outline: none;
    opacity: 1;
    border-radius: 1rem;
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 40px;
        height: 40px;
        background: $midblue;
        cursor: pointer;
        border-radius: 40px;
        z-index: 10;
    }
    &::-moz-range-thumb {
        width: 40px;
        height: 40px;
        background: $midblue;
        cursor: pointer;
        border-radius: 40px;
        z-index: 10;
      }
  }
  
  .range-slider-start{
    height: 20px;
    border-radius: 1rem 0 0 1rem;
    display: block;
    background:$midblue;
    position: absolute;
    top:0;
    left: 0;
    z-index: 1;
  }
  
  .range-slider-controls {
    span{
        &:hover{
            cursor: pointer;
        }
    }
  }

  .progress, .progress-bar{
    border-radius: $progress-border-radius!important;
  }

  .progress-bar-overlap{
    z-index: 1;
  }
  
  .progress-bar-inner{
    width:50%;
    margin-left: -25%;
    height: 100%;
    z-index: 0;
    display: block;
  }
   