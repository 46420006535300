@import '../../variables.scss';

.webinar-card {
    min-height: 21rem;

    &:hover {
        .icon-play-premier {
            background: $premier !important;
        }
        .icon-play-private {
            background: $private !important;
        }
    }

    .team-profile {
        width: 5rem;
        margin: 0 0.25rem;
    }

    &-header {
        min-height: 8.5rem;
        @include media-breakpoint-down(sm){
            min-height: 5.5rem;
        }
    }
}