@import '../variables.scss';


// to-do: this would be better if it was a nav
.footer {
  
    &__link {
      @include transition($nav-link-transition);
      color: $secondary;
      &:hover {
        color: $premier;
      }
    }
  
    &__copyright {
      font-size: 0.8125rem;
    }
}

.footer-cta{
    @include media-breakpoint-down(md){
        -webkit-box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
        border-radius: 50rem !important;
    }
}


.social {
    @include transition($nav-link-transition);
    background: $secondary;
    width: $social-size;
    height: $social-size;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center right;
    &:hover {
        background: $tertiary;
    }
    &-facebook {
        mask-image: url("images/footer-facebook.svg");
    }
    &-twitter {
        &:hover {
            background: $asset;
        }
        mask-image: url("images/footer-twitter.svg");
    }
    &-youtube {
        &:hover {
            background: $premier;
        }
        mask-image: url("images/footer-youtube.svg");
    }
    &-linkedin {
        mask-image: url("images/footer-linkedin.svg");
    }
}

.share {
    @include transition($nav-link-transition);
    background: $midblue;
    width: $social-size;
    height: $social-size;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center right;

    &-blog, &-guide {
        width: 1.25rem;
        height: 1.25rem;
    }
    &-blog {
        background: $secondary;
    }
    &:hover {
        background: $tertiary;
    }
    &-facebook {
        mask-image: url("images/footer-facebook.svg");
        &:hover {
            background: $asset;
        }
    }
    &-twitter {
        &:hover {
            background: $asset;
        }
        mask-image: url("images/footer-twitter.svg");
    }
    &-youtube {
        &:hover {
            background: $premier;
        }
        mask-image: url("images/footer-youtube.svg");
    }
    &-linkedin {
        mask-image: url("images/footer-linkedin.svg");
        &:hover {
            background: $asset;
        }
    }
    &-whatsapp {
        mask-image: url("images/whatsapp.svg");
        &:hover {
            background: $private;
        }
    }
}

.locations-footer{
    &.text-secondary a:not([class*="btn-"]):not([class*="cta-btn"]):not([class*="circle-inner-link"]){
        text-decoration: none;
        @include transition($nav-link-transition);
        strong{
            color: $secondary!important;
        }
        &:hover{
            color:$premier!important;
            strong{
                color: $secondary!important;
            }
        }
    }
}


