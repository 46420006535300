@import "../../variables";

.video-card {
    &-image {
        max-height: 12rem;
    }
    + .wi-landing-experts{
        margin-top: $spacer*5;
    }
}

.blog-video-wrapper{
    position: relative;
    .cta-btn{
        position: absolute;
        bottom:$spacer;
        left:$spacer;
        z-index: 2;
    }
}

.blog-video iframe, .vimeo-video-image, .lite-vimeo{
    opacity: 0;
    z-index: 0;
    @include transition($transition-base);
}


.blog-video-image{
    @include transition($transition-base);
    &:hover{
        cursor: pointer;
    }
    i{
        position: absolute;
        margin: auto;
        top:0;
        left: 0;
        right:0;
        bottom: 0;
        font-size: 5.3rem;
        height: 5.3rem;
        width: 6rem;
        &:before{
            position: relative;
            z-index: 1;
            text-shadow: $box-shadow;
        }
        &:after{
            content:'';
            background:$secondary;
            height:3rem;
            width: 3rem;
         
            position: absolute;
            margin: auto;
            top:0;
        left: 0;
        right:0;
        bottom: 0;
        z-index: 0;
        }
    }
}

.youtube-thumbnail{
    background-repeat: no-repeat;background-size: cover;background-position: center;
}


lite-vimeo:hover > .ltv-playbtn{
    background: $premier !important;
}

lite-vimeo.lite-vimeo-primary:hover > .ltv-playbtn{
    background: $primary !important;
}

lite-youtube > .lty-playbtn{
    filter:none!important;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgba(23, 35, 34, .75)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
}

lite-youtube:hover > .lty-playbtn{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(240, 100, 90)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
}
lite-youtube.lite-youtube-primary:hover > .lty-playbtn{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(24, 38, 58)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
}

