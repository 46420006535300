.img-loaded {
    opacity: 1;
}
img {
    transition: opacity 750ms ease-in-out;
}
.img-at-home {
    max-height: $at-home-max;
    @include media-breakpoint-up(lg){
        max-width: $at-home-max;
    }
}

.img-contain {
    object-fit: contain;
}
.img-cover {
    object-fit: cover;
}

.images-fluid {
    img {
        max-width: 100%;
        height: auto;
    }
}

// animated diagrams
.animation-reveal {
    max-width: 100%;
    canvas, div {
        max-width: 100%;
    }
    div {
        display: none!important;
    }
}

.double_logos{
    .img-fluid{
        width: 17rem;
    }
}

.highlight-image{
    width: 500px;
    margin:0 auto;
    display: block;
}