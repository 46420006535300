@import "../../variables";

.animate {

    &-fp {
        clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
        @include media-breakpoint-down(lg) {
            clip-path: polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
        }
        @include media-breakpoint-down(md) {
            clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);
        }
        @include media-breakpoint-down(sm) {
            clip-path: polygon(100% 0, 100% 97%, 50% 100%, 0 97%, 0 0);
        }

        margin-bottom: -2.5rem;
    }

    &-line {
        left: 84%;
        top: 28%;
        width: 40%;
        z-index: -1;
        
        @include media-breakpoint-down(xl) {
            top: 38%;
        }
    }

    &-circle {
        min-width: 10rem;
        // max-width: 14rem;
    }

    img {
        width: 80%;
        @include media-breakpoint-down(xl) {
            width: 10rem;
        }
    }
}
.grid {
    display: grid;
    display: -ms-grid;
    &-4 {
        gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
        -ms-grid-columns: repeat(4, 1fr);
        
        @include media-breakpoint-down(xl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(1, 1fr);
            gap: 0;
        }
    }
    &-8 {
        grid-template-columns: repeat(7, 1fr);
    }
}

.min {
    &-25r {
        min-height: 25rem;
    }

    &-mob-h {
        @include media-breakpoint-down(lg) {
            min-height: 15rem;
        }

        @include media-breakpoint-between(sm, lg) {
            min-height: 12rem;
        }
    }
}

.h-fit {
    height: fit-content;
}

.vertical-line {
    border-left: 2px solid $premier;
    height: 12rem;
    position: absolute;
    left: 5.25rem;
    margin-left: -3px;
    top: 120px;
    z-index: -1;
    @include media-breakpoint-between(sm, lg) {
        height: 4rem;
        top: 143px;
    }
}
