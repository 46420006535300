@import "../../../variables";



$wi-height: 140px;
$wi-author-width: 130px;
$wi-modal-zindex: 10001;

.wealth-index {
    &-title {
        border-radius: 200px 0 0 200px!important;
        &:first-of-type {
            margin-top: 24px !important;
            @include media-breakpoint-down(lg){
                margin-top: 0 !important;
            }
        }

        + .wealth-index-sub-title {
            margin-top: 30px !important;
        }

        margin-bottom: 30px !important;
        height: $wi-height;
        img {
            object-fit: contain;
            height: 100%;
        }
        .background {
            height: $wi-height;
            right: 0;
            width: 50vw;
            z-index: -1000;
            @include media-breakpoint-down(lg){
                left: 0;
                width: 100vw;
            }
        }

        // h1 {
        //     font-size: 40px;
        //     @include media-breakpoint-down(lg){
        //         font-size: 25px;
        //     }
        // }
    }

    &-anchor {
        top: -200px !important;
        display: block;
        position: relative;
        @include media-breakpoint-down(lg){
            top: -630px !important;
        }
        @include media-breakpoint-down(lg){
            &-video, &-testimonial, &-text, &-faq, &-cards, &-map {
                top: -400px !important;
            }
        }
    }

    &-sub-title {
        margin-top: 80px !important;
        margin-bottom: 40px;
        &:first-of-type {
            margin-top: 24px !important;
        }

        .background {
            right: 0;
            width: 50vw;
            z-index: -1000;
        }

        h3 {
            margin-bottom: 20px;
        }
    }

    &-author {
        .team-profile {
            width: $wi-author-width;
            margin: 0;
            @extend .col-3;
        }
    }

    &-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 200px;
        z-index: 2;
       //z-index: $wi-modal-zindex;
        &-modal{
            z-index: $wi-modal-zindex+1; 
        }
        @media screen and (min-width: 1070px) and (max-height: 800px) {
            > h5{
                display: none!important;
            }
        }
        
        nav#{&}{
            //overflow-y: scroll;
            height: auto!important;
        }
        @include media-breakpoint-down(lg){
            top: 73px !important;
        }
        // &.col-lg-3{
        //     @include media-breakpoint-down(lg){
        //         top: 138px !important;
        //         background:yellow!important;
        //         padding:0;
        //         //width:101%;
        //         margin-left:-$grid-gutter-width/2;
        //     }
        // }
            
        &-mobile {
            border-bottom-width: 2px !important;
            .accordion-button {
                display: flex;
                align-items: center;
                font-family: $headings-font-family;
                font-size: 1.125rem !important;
                &::after {
                    content: '';
                    display: block;
                    background: $secondary;
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-left: auto;
                    -webkit-mask-image: url("images/accordion-icon-dust.svg");
                    mask-image: url("images/accordion-icon-dust.svg");
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-position: top;
                    mask-position: top;
                    -webkit-mask-size: contain;
                    mask-size: contain;
                    @include transition($nav-link-transition);
                }
                &.bg-midblue{
                    &:after{
                        display: none!important;
                    }
                }
            }
            #menuItems .nav-item:last-child{
                border-bottom: none!important;
            }
        }
        .nav-link {
            font-family: $headings-font-family;
            padding-top: 16px;
            padding-bottom: 16px;
            @media screen and (min-width: 1070px) and (max-height: 800px) {
                padding-top: 12px;
                padding-bottom: 12px;
                font-size: $font-size-sm;
              }
            &:hover {
                @include media-breakpoint-down(lg){
                    color: $secondary !important;
                }
            }
            &.active {
                color: $premier !important;
            }
        }
        .sticky-cta{
            @media screen and (min-width: 1070px) and (max-height: 800px) {
                margin-top: 15px!important;
                margin-bottom: 15px!important;
                font-size: $font-size-sm;
                .h5{
                    font-size: $h5-font-size*.9;
                }
            }
            padding-bottom: 100%;
            height: 0;
            position: relative;
            > span{
                position: absolute;
                height: 100%;
                width: 100%;
            }
            @include media-breakpoint-down(xl){
                font-size: $font-size-sm;
                .h5{
                    font-size: $h5-font-size*.9;
                }
            }
        }
    }

    &-awards {
        .h1 {
            font-size: 1.875rem !important;
        }
    }

    &-text {
        p {
            margin-bottom: 30px !important;
        }

        blockquote {
            p {
                @extend .h4;
                color: $premier;
            }
        }

        h4 {
            &:not(:first-of-type) {
                margin-top: 40px !important;
            }
        }

        h4 {
            letter-spacing: -0.22px;
        }
    }

    &-download {
        .icon-arrow-right {
            background-color: $secondary !important;
            @include transition($nav-link-transition);
        }
        .icon-arrow-link {
            &:hover {
                .icon-arrow-right {
                    background-color: $premier !important;
                }
            }
        }
        .wp-post-image {
            width: 23rem !important;
            top: -30px;
            @include media-breakpoint-down(md){
                margin-top: -3rem;
                margin-bottom: 1.5rem;
            }
        }

        #download {
            top: -260px !important;
            display: block;
            position: relative;
            @include media-breakpoint-down(lg){
                top: -650px !important;
            }
        }

    }
}

.private{
    .wealth-index{
        &-sticky {
            .nav-link:not(.text-dust){
                &.active,&:hover{
                    color: $private!important;
                }
            }
        }
    }
}


.wealth-index-map{
    //background:yellow;
    padding:0 27%;
    margin:15% 0 20%;
    position: relative;
    @include media-breakpoint-down(md){
        padding: 0;
        margin: $spacer*3 0;
    }
    ul{
        font-size: $font-size-sm;
        position: absolute;
        left:0;
        top:-15%;
        @include media-breakpoint-down(md){
            position: relative;
            left:auto;
            top:auto;
            margin-top: $spacer;
        }
        li{
            @include media-breakpoint-down(md){
                display: inline-block;
                margin-right: $spacer;
            }
            &::before{
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background:blue;
                content: '';
                display: inline-block;
                margin-right: 5px;
            }
            &:nth-child(1){
                &::before{
                    background:rgb(240, 100, 90);
                }
            }
            &:nth-child(2){
                &::before{
                    background:rgb(50, 90, 125);
                }
            }
            &:nth-child(3){
                &::before{
                    background:rgb(220, 222, 223);
                }
            }
        }
    }
    &.is-visible{
        .wealth-index-indicator{
            span{
                animation-duration: 3s;
                animation-name: slidein;
                opacity: 1;
            }
        }
    }
}

.wealth-index-indicator{
    width:100%;
    display: block;
    position: absolute;
    top:0;
    left:0;
    @include media-breakpoint-down(md){
        transform: rotate(-180deg)!important;
        top:63%!important;
        bottom:50%!important;
        left:auto!important;
        right:100%!important;
        margin:auto 0;
    }
    span{
        width: 100%;
        opacity: 0;
        display: block;
        border-top:1px solid rgb(240, 100, 90);
        position: relative;
        &:after{
            content: '';
            width:6px;
            height:6px;
            background:rgb(240, 100, 90);
            display: block;
            margin-top:-4px;
            border-radius: 100%;
            position: absolute;
            right:0;
        }
    }
}

.wealth-index-arrow{
    .wealth-index-arrow-title-bottom{
        @include media-breakpoint-up(md){
        flex-direction: column-reverse!important;
        h6{
            margin-top: $spacer*0.5;
        }
        }
    }
    .wealth-index-indicator{
        @include media-breakpoint-down(md){
            top:56%!important;
            bottom:auto!important;
        }
}
}

.wealth-index-arrow-inner{
    height: 0;
    padding-bottom: 100%;
    margin-right:10%;
    margin-left:-10%;
    //background:red;
    h6{
        color:#666;
        font-size:12px;
        font-weight: bold;
        font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }
}

.wealth-index-arrow-img{
    width:80%;
  img{
    margin-left: 0!important;
  }

}

.wealth-index-arrow-text{
    position: absolute;
  margin: 0 auto;
  top: 33%;
  right: 0;
  left: 0;
  display: inline-block;
  //background:green;
  color:white;
        font-size:15px;
        font-weight: normal;
        font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
        text-align: center;
        line-height: 1;
        height: 15px;
        @include media-breakpoint-down(md){
            font-size: 3vw;
            height: 3vw;
        }


}

.wealth-index-world-map{
    margin-bottom: 120px;
    margin-top: 80px;
    img{
        opacity: 1!important;
    }
    .wealth-index-world-charts{
        margin:5%;
        position: relative;
        @include media-breakpoint-down(md){
            padding: 0;
            margin: $spacer*3 0;
        }
    }
    .map-region{
        opacity: 0;
        @include media-breakpoint-down(md){
            opacity: 1;
        }
    }
    .wealth-index-region{
        position: absolute;
        top:0;
        right:0;
        width:12%;
    }
    &.is-visible{
        .wealth-index-indicator{
            span{
                animation-duration: 3s;
                animation-name: slidein;
                opacity: 1;
            }
        }
    }
}


.wealth-index-region{
    position: absolute;
    top:0;
    right:0;
    width:18%;
    @include media-breakpoint-down(md){
        position: relative;
        top:auto!important;
        left:auto!important;
        right:auto!important;
        width: 100%;
    }
    //height:20%;
    &-scotland{
        top:-17%;
        right:20%;
        .wealth-index-indicator{
            top: 138%;
            left: -103%;
            transform: rotate(128deg);
            width: 145%;
            @include media-breakpoint-down(md){
                width: 70%;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                top: 130%;
                left: -106%;
                width: 165%;
                @include media-breakpoint-down(md){
                    width: 60%;
                    right:110%!important;
                }
            }

        }
    }
    &-north-east{
        top:6%;
        right:17%;
        .wealth-index-indicator{
            top: 131%;
            left: -78%;
            transform: rotate(127deg);
            width: 120%;
            @include media-breakpoint-down(md){
                width: 100%;
                top:98%!important;
                right:90%!important;
                transform: rotate(-217deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                top: 123%;
                left: -82%;
                width: 140%;
                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }

        }
    }
    &-yorkshire{
        top:26%;
        right:7%;
        .wealth-index-indicator{
            top: 116%;
            left: -86%;
            transform: rotate(149deg);
            width: 115%;
            @include media-breakpoint-down(md){
                width: 70%;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                transform: rotate(143deg);
                top: 104%;
                width: 140%;
                left: -92%;
                @include media-breakpoint-down(md){
                    width: 60%;
                    right:110%!important;
                }
            }

        }
        
    }
    &-east-midlands{
        top:48%;
        right:0%;
        .wealth-index-indicator{
            top: 80%;
            left: -121%;
            transform: rotate(171deg);
            width: 128%;
            @include media-breakpoint-down(md){
                top:106%!important;
                right:85%!important;
                transform: rotate(-222deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                top: 78%;
                width: 150%;
                @include media-breakpoint-down(md){
                    transform: rotate(-214deg) !important;
                    width: 135%;
                    top: 111% !important;
                    right: 73% !important;
                }
            }

        }
    }
    &-east-of-england{
        top:70%;
        right:0%;
        .wealth-index-indicator{
            top: 48%;
            left: -89%;
            transform: rotate(202deg);
            width: 97%;
            @include media-breakpoint-down(md){
                width: 72%;
                top:80%!important;
                transform: rotate(-200deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                transform: rotate(188deg);
                top: 38%;
                left: -87%;
                width: 110%;
                @include media-breakpoint-down(md){
                    top: 83% !important;
                    right: 91% !important;
                    width: 80%;
                    transform: rotate(-194deg) !important;
                  
                }
            }

        }

        
    }
    &-greater-london{
        top:93%;
        right:7%;
        .wealth-index-indicator{
            top: 13%;
            left: -86%;
            transform: rotate(235deg);
            width: 115%;
            @include media-breakpoint-down(md){
                width: 92%;
                top:0%!important;
                bottom:53%!important;
                right:95%!important;
                transform: rotate(-165deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                top: 10%;
                left: -82%;
                transform: rotate(228deg);
                width: 120%;
                @include media-breakpoint-down(md){
                    width: 87%;
                    top: 0 !important;
                    bottom: 55% !important;
                    transform: rotate(-167deg) !important;
                    right:101% !important;
                }
            }

        }
        
    }
    &-south-east{
        top:100%;
        right:27%;
        .wealth-index-indicator{
            top: -2%;
            left: 0%;
            transform: rotate(267deg);
            width: 89%;
            @include media-breakpoint-down(md){
                width: 135%;
                top:0%!important;
                bottom:96%!important;
                right:81%!important;
                transform: rotate(-141deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                transform: rotate(270deg);
                left: -3%;
                @include media-breakpoint-down(md){
                    transform: rotate(-143deg) !important;
                    bottom: 98% !important;
                    right: 80% !important;
                }
            }

        }
        
    }
    &-south-west{
        top:100%;
        right:57%;
        .wealth-index-indicator{
            top: 4%;
            left: 28%;
            transform: rotate(319deg);
            width: 108%;
            @include media-breakpoint-down(md){
                width:117%;
                top:0%!important;
                bottom: 73%!important;
                right:81%!important;
                transform: rotate(-141deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                transform: rotate(312deg);
                left: 33%;
                top: 9%;
                @include media-breakpoint-down(md){
                    transform: rotate(-151deg) !important;
                    width: 110%;
                    right: 87% !important;
                    bottom: 85% !important;
                }
            }

        }
    }
    &-wales{
        top:82%;
        right:77%;
        .wealth-index-indicator{
            top: 25%;
            left: 88%;
            transform: rotate(335deg);
            width: 154%;
            @include media-breakpoint-down(md){
                width: 95%;
                top:75%!important;
                transform: rotate(-197deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                transform: rotate(342deg);
                top: 22%;
                left: 49%;
                width: 190%;
                @include media-breakpoint-down(md){
                    width: 90%;
                    top:76%!important;
                    right:105%!important;
                }
            }

        }


    }
    &-west-midlands{
        top:64%;
        right:68%;
        .wealth-index-indicator{
            top: 52%;
            left: 94%;
            transform: rotate(357deg);
            width: 129%;
            @include media-breakpoint-down(md){
                width: 71%;
                top:0%!important;
                bottom: 60%!important;
                right:83%!important;
                transform: rotate(-143deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                width: 170%;
                transform: rotate(361deg);
                left: 53%;
                top: 47%;
                @include media-breakpoint-down(md){
                    width: 71%;
                    top:0%!important;
                    bottom: 70%!important;
                    right:85%!important;
                    transform: rotate(-156deg)!important;
                }
            }

        }

    
    }
    &-north-west{
        top:38%;
        right:77%;
        .wealth-index-indicator{
            top: 84%;
            left: 92%;
            transform: rotate(373deg);
            width: 176%;
            @include media-breakpoint-down(md){
                width: 119%;
                top:0%!important;
                bottom: 72%!important;
                right:82%!important;
                transform: rotate(-143deg)!important;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                width: 215%;
                left: 52%;
                top: 87%;
                transform: rotate(369deg);
                @include media-breakpoint-down(md){
                    width: 115%;
                    top:0%!important;
                    bottom:83%!important;
                    right:85%!important;
                    transform: rotate(-153deg)!important;
                } 
            }

        }

        
        
    }
    &-northern-ireland{
        top:10%;
        right:75%;
        .wealth-index-indicator{
            top: 128%;
            left: 57%;
            transform: rotate(416deg);
            width: 113%;
            @include media-breakpoint-down(md){
                width: 62%;
            }
        }
        &.wealth-index-arrow{
            .wealth-index-indicator{
                width: 140%;
                left: 29%;
                transform: rotate(409deg);
                top: 121%;
                @include media-breakpoint-down(md){
                    width: 53%;
                    right: 109% !important;
                  }
                } 

        }



        
    }
    &-spain{
        top:-22%!important;
        right:53%!important;
        .wealth-index-indicator{
            transform: rotate(77deg);
            top: 185%;
            left: -21%;
            width: 200%;
            }
    }
    &-portugal{
        top:35%!important;
        right:54%!important;
        .wealth-index-indicator{
            width: 45%;
            left: 64%;
            top: 42%;
            transform: rotate(-43deg);
        }
        
    }
    &-jersey{
        top: -22% !important;
        right: 43% !important;
        .wealth-index-indicator{
            transform: rotate(100deg);
            top: 177%;
            left: -47%;
            width: 164%;
        }
        
    }
    &-france{
        top: -30% !important;
        right: 35% !important;
        .wealth-index-indicator{
            transform: rotate(112deg);
            top: 195%;
            left: -102%;
            width: 215%;
        }
        
    }
    &-sweden{
        top: -24% !important;
        right: 23% !important;
        .wealth-index-indicator{
            transform: rotate(136deg);
            top: 158%;
            left: -134%;
            width: 203%;
        }
        
    }
    &-germany{
        top: -14% !important;
        right: 12% !important;
        .wealth-index-indicator{
            transform: rotate(153deg);
            top: 148%;
            left: -226%;
            width: 285%;
        }
        
    }
    &-switzerland{
        top: -6% !important;
        right: 0% !important;
        .wealth-index-indicator{
            transform: rotate(162deg);
            top: 130%;
            left: -322%;
            width: 360%;
        }
    }
    &-japan{
        top: 26% !important;
        right: -5% !important;
        .wealth-index-indicator{
            transform: rotate(180deg);
            top: 70%;
            left: -66%;
            width: 100%;
        }
        
    }
    &-hong-kong{
        top: 48% !important;
        right: -5% !important;
        .wealth-index-indicator{
            transform: rotate(197deg);
            top: 37%;
            left: -128%;
            width: 155%;
        }
        
    }
    &-new-zealand{
        top: 88% !important;
        right: 12% !important;
        .wealth-index-indicator{
            transform: rotate(-36deg);
            top: 27%;
            left: 68%;
            width: 90%;
          
        }
        
    }
    &-australia{
        top: 88% !important;
        right: 32% !important;
        .wealth-index-indicator{
            transform: rotate(-33deg);
            top: 6%;
            left: 62%;
            width: 185%;
          
        }
        
    }
    &-singapore{
        top: 61% !important;
        right: 26% !important;
        .wealth-index-indicator{
            transform: rotate(-51deg);
            top: 38%;
            left: 57%;
            width: 70%;
          
        }
        
    }
    &-monaco{
        top: 66% !important;
        right: 48% !important;
        .wealth-index-indicator{
            transform: rotate(-80deg);
            top: -60%;
            left: 6%;
            width: 130%;
          
        }
        
    }
    &-virgin-islands{
        top: 91% !important;
        right: 55% !important;
        .wealth-index-indicator{
            transform: rotate(-109deg);
            top: -80%;
            left: -84%;
            width: 185%;
          
        }
        
    }
    &-cayman-islands{
        top: 88% !important;
        right: 73% !important;
        .wealth-index-indicator{
            transform: rotate(-73deg);
            top: -71%;
            left: -10%;
            width: 175%;
          
        }
        
    }
    &-bahamas{
        top: 64% !important;
        right: 81% !important;
        .wealth-index-indicator{
            transform: rotate(-35deg);
            top: -24%;
            left: 48%;
            width: 135%;
          
        }
        
    }
    &-united-states{
        top: 36% !important;
        right: 89% !important;
        .wealth-index-indicator{
            transform: rotate(-12deg);
            top: 48%;
            left: 80%;
            width: 110%;
          
        }
        
    }
    &-canada{
        top: -6% !important;
        right: 89% !important;
        .wealth-index-indicator{
            transform: rotate(48deg);
            top: 122%;
            left: 48%;
            width: 140%;

          
        }
        
    }
    &-not-sure-yet{
        top: -28% !important;
        right: 76% !important;
        .wealth-index-indicator{
            display: none;

          
        }
        
    }
    canvas, .wealth-index-arrow-inner{
        transition: transform 0.3s ease-in-out;
    }
    canvas{
        z-index: 1;
        position: relative;
    }
}



.map-region{
    height: 0;
    @include media-breakpoint-down(md){
        height: auto;
    }
    img{
        display: inline-block;
        margin-left: -4px;
        vertical-align: middle;
    }
    &-charts{
        width: 40%;
        margin-left:10%;
        display: inline-block;
        vertical-align: middle;
    }
}

.map-region{
    &-2,&-3,&-4,&-5{
        @include media-breakpoint-down(md){
            display: none;
        }
    }
    &-1,&-2,&-3,&-4,&-5{
        > span{
            position: absolute;
            z-index: 0;
        }
    }
    &-1{
        > span{
            width: 30%;
            height:50%;
        }
    }
    &-2{
        > span{
            &:nth-child(1){
                z-index: 1;
                width: 7%;
                height:14%;
                right:40%;
                top:41%;
            }
            &:nth-child(2){
                z-index: 2;
                width: 11%;
                height:12%;
                right:35%;
                top:55%;
            }
            &:nth-child(3){
                z-index: 3;
                width: 6%;
                height:22%;
                right:46%;
                top:48%;
            }
        }
    }
    &-3{
        > span{
            &:nth-child(1){
                z-index: 4;
                width: 8%;
                height: 14%;
                right: 35%;
                top:65%;
            }
            &:nth-child(2){
                z-index: 3;
                width: 10%;
                height: 14%;
                right: 28%;
                top:69%;
            }
            &:nth-child(3){
                z-index: 5;
                width: 4%;
                height: 4%;
                right: 34%;
                top:83%;
            }
            &:nth-child(4){
                z-index: 2;
                width: 14%;
                height: 12%;
                right: 29%;
                top:80%;
            }
        }
    }
    &-4{
        > span{
            &:nth-child(1){
                z-index: 5;
                width: 10%;
                height: 20%;
                right: 49%;
                top: 66%;
            }
            &:nth-child(2){
                z-index: 2;
                width: 9%;
                height: 13%;
                right: 41%;
                top: 68%;
            }
            &:nth-child(3){
                z-index: 1;
                width: 22%;
                height: 12%;
                right: 41%;
                top: 85%;
            }
        }
    }
    &-5{
        > span{
            z-index: 1;
            width: 12%;
            height: 13%;
            right: 60%;
            top: 47%;
        }
    }
}

.wealth-index-map-filter{
    label{
        @extend .h4;
    }
   select{
    display: block;
    margin-top: $spacer/2;
    width: 100%;
   } 
}

@keyframes slidein {
    from {
      width: 0%;
    }
  
    to {
      width: 100%;
    }
  }