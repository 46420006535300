@import "../../variables";
#office-findus{
    .fa-lg{
        line-height: 1.25;
    }
    .btn-tertiary{
        &[aria-expanded="true"]{
            background: $midblue!important;
            border-color: $midblue!important;
            pointer-events: none;
            .fa-lg{
                color:$premier!important;
            }
        }
        span{
            font-family: $headings-font-family;
        }
    }
}

.premier .office-tile, .office-tile{
    &:hover{
        color: inherit!important;
    }
}
