.tabs-wrapper {
    filter: drop-shadow(0rem 0rem 30px #2E3B4D33);
    button {
        filter: none;
    }
    .nav-link {
        will-change: filter;
        // transition: none;
    }
}
.tab-content-wrapper {
    position: relative;
    @include media-breakpoint-up(lg){
        top: -2rem;// repeated below as media page is always tabs, team is pills on mobile
    }
    &.tab-content-wrapper-xs {
        top: -2rem;
    }
}

.media-tab-content {
    top: -2rem;
}
.nav-link {
    &:focus {
        outline: none;
        color: $nav-link-hover-color;
    }
}
.active-premier.active {
    background: $premier;
    color: $secondary;
}