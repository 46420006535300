@use "sass:math";

// declare functions and global variables
@import "bootstrap/scss/functions";
$variable-prefix: bs- !default;
$enable-rounded: false;
$card-border-width: 0;
$nav-tabs-border-width: 0;
$nav-link-padding-y: 1rem;

$primary: #18263a;
$secondary: #fff5e6;
$tertiary: #325a7d;
$midblue: #22384F;
$white: #ffff;

$premier: #f0645a;
$private: #49796b;
$asset: #3879b2;
$lorica: #b04f7d;
$gold: #EC9F22;
$cta: #9DE6E4;

$tablehead: #D6D6D2;
$tint: #E7E0D5;
$rangeTrack: #909BA6;
$progressTrack: #98ACBD;

$navbar-dark-active-color: $premier;
$navbar-dark-color: $secondary;
$navbar-dark-hover-color: $premier;
$link-hover-color: $premier;

// Brand colour map
$brand-colors: (
  premier: $premier,
  private: $private,
  asset: $asset,
  lorica: $lorica,
  gold: $gold,
  dust: $secondary,
  navy: $primary,
  steel: $tertiary,
  midblue: $midblue,
  cta: $cta,
  tint:$tint
);
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  gold: $gold,
  light: $secondary,
  dark: $primary,
  cta: $cta,
  tint:$tint
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "10x7": calc(7 / 10 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

$body-color: $midblue;
$body-bg: $secondary;

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop(
  map-merge($brand-colors, $theme-colors), to-rgb, "$value"
) !default;

// scss-docs-start utilities-colors
$utilities-colors: map-merge(
  $theme-colors-rgb,
  (
    "white": to-rgb($white),
    "body":  to-rgb($body-color)
  )
) !default;
// scss-docs-end utilities-colors

// scss-docs-start utilities-text-colors
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text") !default;
// scss-docs-end utilities-text-colors

// scss-docs-start utilities-bg-colors
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg") !default;
// scss-docs-end utilities-bg-colors



$font-family-sans-serif: 'Roboto', sans-serif;

@font-face {
  font-family: 'TiemposHeadlineWeb';
  src: url('./typography/TiemposHeadlineWeb-Medium.woff2') format('woff2');
}

$headings-font-family: 'TiemposHeadlineWeb';
$headings-letter-spacing: -1%;
$font-size-base: 1rem;
$lead-font-weight: 400;

$arrow-size: 1rem;
$social-size: 2rem;
$close-size: 1.25rem;
$logo-width: 10rem;
$back-size: 1.5rem;
$logo-height: $logo-width * 0.26;

$modal-inner-padding: 0;

$nav-link-translate-transition:  transform .15s ease-in-out;

$h1-font-size:                $font-size-base * 3.125 !default; // 50px
$h2-font-size:                $font-size-base * 1.875 !default; // 30px
$h3-font-size:                $font-size-base * 1.563 !default; // 25px
$h4-font-size:                $font-size-base * 1.375 !default; // 22px
$h5-font-size:                $font-size-base * 1.25 !default;  // 20px
$h6-font-size:                $font-size-base * 1.125 !default; // 18px
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: 0.875rem,
  6: $h6-font-size
) !default;


$spacer: 1rem;
$spacers: (
  0:  0,
  1:  $spacer * .25,
  2:  $spacer * .5,
  3:  $spacer,
  4:  $spacer * 1.5,
  5:  $spacer * 3,
  6:  $spacer * 2.5,
  7:  $spacer * 3.75,  // 60 px
  8:  $spacer * 5,     // 80 px
  9:  $spacer * 6.25,  // 100 px
  10: $spacer * 7.5,   // 120 px
  11:  $spacer * 2,
);

$enable-negative-margins: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1155px,//1070
  xl: 1288px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1038px,
  xl: 1288px,
);

$modal-content-bg: $primary;
$navbar-nav-link-padding-x: 0;

$accordion-border-color: transparent;
$accordion-padding-x: 0;

$accordion-button-focus-box-shadow: none;

$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-focus-opacity: 1;

$btn-font-size-lg: $font-size-base * 1.125; //18px

@import "bootstrap/scss/variables";

$carousel-indicator-width: 1rem * 0.66;
$carousel-indicator-height: 1rem * 0.66;
$carousel-indicator-opacity: 1;
$carousel-indicator-active-bg: $premier;

$container-padding-x: $grid-gutter-width;
$navbar-brand-margin-end: 0;
$navbar-toggler-padding-y: 0;

$header-height: 7.7rem; //was 6.5
$header-height-mobile: 4.6rem; // was 5.5rem
$sticky-nav-height: 4.3rem;

// $form-select-indicator-color: $secondary;
// $form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");


$at-home-max: 608px;



/* forms */
$form-feedback-valid-color:         $private;
$form-feedback-invalid-color:       $premier;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
);

$input-bg: $primary;
$input-border-width: 0;
$input-focus-bg: $primary;
$input-color: $secondary;
$input-focus-color: $input-color;
$input-border-width: 0;

$form-select-color: $secondary;
$form-select-bg: $input-bg;
$form-select-border-width: $input-border-width;

// $input-placeholder-color: tint-color($primary, 50%);
$input-placeholder-color:$primary;

$border-radius:12px;


// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 $font-size-base;
$tooltip-max-width:                 400px; //200px default
$tooltip-color:                     $body-color;
$tooltip-bg:                        $body-bg;
$tooltip-border-radius:             $border-radius;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 $spacer*0.75;
$tooltip-padding-x:                 $spacer*0.75;

$tooltip-arrow-width:               $spacer*1.75;
$tooltip-arrow-height:              $spacer*0.75;
$tooltip-arrow-color:               $tooltip-bg;

//form floating
$form-floating-height:            add(2.75rem, $input-height-border);
$form-floating-padding-y:         $input-padding-x;


//progress bar (wi tool)
$progress-height:                   40px;
$progress-bg:                       $progressTrack;
$progress-border-radius:            40px;
$progress-bar-bg:                   $tertiary;