$homepage-card-duration: 0.3s;

.homepage-card {
    @include media-breakpoint-up(lg) {
        height: 270px; // 302px with video
        transition: height $homepage-card-duration ease-in-out;
    }
    img {
        @include media-breakpoint-down(lg){
            display: none;
        }
    }
    overflow: hidden;
    bottom: 0;
    cursor: pointer;
    &.active {
        
        left: 0;
        right: 0;
        //height: 70vh;
        img {
            @include media-breakpoint-down(lg){
                display: block;
            }
        }
        .list {
            transform: translateY(0);
            //display: block;
            //height: auto;
            @include media-breakpoint-down(lg){
                display: block;
            }
            position: relative;
            z-index: 1;
        }
        // spin arrow to point down
        .arrow-link-up {
            &::after {
                transition: transform 0.3s ease-in-out;
                -webkit-mask-image: url("images/right-arrow-premier.svg");
                mask-image: url("images/right-arrow-premier.svg");
                transform: rotate(90deg)
            }
        }
    }
    .list {
        @include media-breakpoint-up(lg) {
            transition: transform $homepage-card-duration ease-in-out;
            //display: none;
            transform: translateY(100%);   
        }
        @include media-breakpoint-down(lg){
            display: none;
        }
        .nav-link {
            padding: $nav-link-padding-y * 0.75 0;
        }
    }

    &:not(.active):hover {
        .arrow-link-up {
            &::after {
                transform: translateY(-10px) rotate(-90deg);
            }
        }
    }
    
}
#homepage-statement {
    @include media-breakpoint-down(lg){
        font-size: $h2-font-size;
    }
}

#homepage-intro{
    text-decoration: none;
    @include transition($nav-link-transition);
}


#homepage-brand, #homepage-statement {
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.3s;
    &.opacity-0 {
        transition-delay: 0;
    }
}

.homepage-card-wrapper {
    // prevent row from collapsing if all are transitioning
    @include media-breakpoint-up(lg) {
        height: 270px; // 302px with video
    }
}
#homepage-brand {
    padding-top: 26%; // 1/3.85
    background: url("images/saltus-logo-premier.svg") no-repeat bottom right;
}
