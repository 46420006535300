

.fade-in-and-out {

    opacity: 0;
    -webkit-animation: fadeinout 1s linear forwards;
    animation: fadeinout 1s linear forwards;
}

@-webkit-keyframes fadeinout {
    100% { opacity: 1; }
}

@keyframes fadeinout {
    100% { opacity: 1; }
}

.wi-landing-section {
    scroll-margin-top: 13rem;
}

.pt {
    &-15 {
        padding-top: 15rem;
    }
    &-17 {
        padding-top: 17rem;
    }
}

.col-grid-3 {
    display: grid;
    grid-template-columns: repeat(1, auto);
    column-gap: 2rem;
    @include media-breakpoint-up(md){
        grid-template-columns: repeat(2, auto);
    }
    @include media-breakpoint-up(lg){
        grid-template-columns: repeat(3, auto);
    }
}

.rounded-xl {
    border-radius: 1rem !important;
  }
  

.remove-last-margin{
    > *:last-child{
        margin-bottom: 0!important;
    }
}