@import "../variables";

.quote-carousel {
    .carousel{
        min-height: 0!important;
    }
    .carousel-item{
        display: block!important;
        transition:visibility .2s ease-in-out, opacity .2s ease-in-out!important; 
            visibility:hidden!important; opacity:0!important;
        &.active{
            visibility:visible!important; opacity:1!important;
        }
    }
    &-indicators {
        right: $spacer * 1.75;
        top: $spacer * 1.75;
        // undo carousel-indicators
        display: block;
        bottom: auto;
        left: auto;
        margin: 0;
        &.logo-indicators{
            top:3.75rem;
        }
    }
    
    &-indicator {
        //height: $spacer * 0.66;
        //width: $spacer * 0.66;
        background-color: transparent !important;
        cursor: pointer;
        display: inline-block;
        &.active {
            background: $secondary !important;
        }
        &:hover {
            border-color: $premier!important;
        }
    }
    .carousel-control-next{
        padding-right: $spacer;
    }
    .carousel-control-prev{
        padding-left: $spacer;
    }
    .team-profile{
        margin-left: 0!important;
        margin-right: 0!important;
    }
    .carousel-item{
        display: flex!important;
        @include media-breakpoint-down('md'){
            height: 100%!important;
            
        }
        
    }
}

// this should be a mixin / merge
@include media-breakpoint-up('md'){
    .h4-md {
        margin-top: 0; // 1
        margin-bottom: $headings-margin-bottom;
        font-family: $headings-font-family;
        font-style: $headings-font-style;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h4-font-size);
    }
}

.vacancies-list{
    @include media-breakpoint-up('lg'){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    }
    
}

.careers_overview{
    .team-profile{
        width: 9rem;
        margin: 0!important;
    }
    .rounded-pill{
        .arrow-link:after{
            transition: all 0.3s ease-in-out;
        }
        &:hover{
            .arrow-link:after{
                transform: translateX(10px);
            }
        }
    }
}

.careers_cta{
    a:not([class*="btn"]):not([class*="rounded-pill"]){
        text-decoration: none;
        white-space: nowrap;
        &:hover{
            text-decoration: underline!important;
            color:$secondary!important;
        }
    }
    @include media-breakpoint-only('md'){
        h3{
            font-size: $h4-font-size;
        }
        h5{
            font-size: $h6-font-size;
        }
    }
    @media (max-width: 980px) {
        .col-md-6{
            width: 100%;
            flex: 0 0 100%;
            + .col-md-6{
                margin-top: $spacer;
            }
        }
    }
}