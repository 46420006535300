@import "../../variables";

.dark-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $primary;
    color: $secondary;
    &:hover {
        background-color: $secondary;
        color: $primary;
    }
    &-blog {
        &:hover {
            background-color: $primary !important;
            color: $secondary;
        }
    }
    &.premier {
        &:hover {
            background-color: $premier !important;
            color: $secondary;
        }
    }
    &.private {
        &:hover {
            background-color: $private !important;
            color: $secondary;
        }
    }
}

.premier-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $premier;
    color: $secondary;
    &:hover {
        background-color: $secondary;
        color: $premier !important;
    }
    &-blog {
        &:hover {
            background-color: $premier !important;
        }
    }
}

.private-btn {
    @include transition($nav-link-transition);
    cursor: pointer;
    background-color: $private;
    color: $secondary !important;
    &:hover {
        background-color: $secondary;
        color: $private !important;
    }
}

.dust-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $secondary;
    color: $primary;
    &:hover {
        background-color: $premier;;
        color: $primary;
    }
}

.gold-btn {
    cursor: pointer;
    @include transition($nav-link-transition);
    background-color: $gold;
    color: $primary;
    &:hover {
        background-color: $secondary;;
        color: $gold;
    }
}

.download-prompt {
    .dark-btn {
        background-color: $secondary;
        color: $primary;
    }
}

.card-button[aria-expanded="true"]{
    &.arrow-link::after {
        transform: translateY(0) rotate(90deg);
    
    }
  }