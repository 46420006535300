@import "../../variables";

.cta_split{
    .cta_half{
        @include media-breakpoint-up(lg){
        width: 100%!important;
        float: none;
               &:not(.cta_blog) {
                width: 100%!important;
            }
                
            }
        }
}

.cta_half{
    @include media-breakpoint-down(lg){
        .bg-cta{
            min-height: 270px;
        }
    }
    .row{
        @media (min-width: 450px) {
            display: block!important;   
     
        }
    }
    @include media-breakpoint-up(sm){  
        width: 80%!important;
    }
    @include media-breakpoint-up(md){
              
        width: 70%!important;
    }
    @include media-breakpoint-up(lg){
        width: 50%!important;
        float: left;
               &:not(.cta_blog) {
                width: 41.66666667%!important;
            }
                
            }
           
}

.cta-image{
    @media (min-width: 450px) {
        display: block!important;
        padding:0!important;
        shape-outside: inset(50px 0px 0px  20px);
  //background-color: #ddd;
  width: 220px;
  height: 170px;
  float: right;
  img{
    margin-top: 60px;
    margin-left: 20px;
    width: 180px;
    
}
    }
    
    &.cta-im{
        @media (min-width: 450px) {
        width: 150px;
        height: 200px;
        img{
            width: 130px;
        }
    }
    }
}


.cta-text{
    @media (min-width: 450px) {
        width:auto!important;
        display: block!important;
        &.cta-im{
            height: 200px;
        }
    }
}

.cta-btn{
    background:$cta;
    text-decoration:none;
    display: inline-block;
    color:$primary;
    position: relative;
    &:hover{
        .fa-saltuscta-1{
            -webkit-animation:cta_arrow 1s ease-out forwards;
        }
    }
    &:hover,&:active,&:focus{
        color:$primary!important;
    }
    span{
        font-family: $headings-font-family;
        
       
    }
    span, i{
        display: inline-block;
        vertical-align: middle;
      
    }
    
}


@-webkit-keyframes cta_arrow {
    0%{position: absolute; left:$spacer * .25;opacity:0;transform: scale(0,0);}
    50% {transform: scale(1,1);opacity: 1}
    100% {position: absolute;right:$spacer * .25;transform: scale(1,1);opacity: 1;}
}

@-webkit-keyframes cta_arrow_nav {
    0%{position: absolute; left:0;opacity:0;transform: scale(0,0);}
    50% {transform: scale(1,1);opacity: 1;}
    100% {position: absolute;right:0;transform: scale(1,1);opacity: 1;}
}

.cta-icon{
    opacity: 0;
    @include transition($transition-base);
    &.fa-loaded{
        opacity: 1;
    }
}

.cta-arrow{
   width: 30px;
   height: 30px;
   .fa-saltuscta-1{
    &:after{
        content:'';
        background:$secondary;
        height:1rem;
        width: 1rem;
        position: absolute;
        margin: auto;
        top:0;
        left: 0;
        right:0;
        bottom: 0;
        z-index: 0;
    }
   }
   i{
    position: relative;
    &:before{
        position: relative;
        z-index: 1;
    }
   }
   &-basic{
    .fa-saltuscta-1{
        opacity: 0;
    }
    .fa-saltuscta-2{
        position: absolute;
        right:$spacer*0.8;
        top:$spacer*0.9;
        font-size: 0.75rem;
    }
    &:hover{
        .fa-saltuscta-1{
            -webkit-animation:cta_arrow 1s ease-out forwards;
        }
    }
    
   }
   &-nav{
    .fa-saltuscta-1{
        opacity: 0;
        color:$cta;
        &:after{
            background: $primary;
        }
    }
    .cta-arrow{
        opacity: 0;
    }
    .cta-phone{
        position: absolute;
        right:0.5rem;
    }
    &:hover{
        .cta-arrow{
            opacity: 1;
        }
        .fa-saltuscta-1{
            -webkit-animation:cta_arrow_nav 1s ease-out forwards;
            
        }
    }
    
   }
}

    .cta-btn-dark{
        background:$primary;
        color:$secondary;
        &:hover,&:active,&:focus{
            color:$secondary!important;
        }
        .cta-arrow{
            i{
                &.fa-saltuscta-1:after{
                    background:$primary;
                }
                &.fa-saltuscta-1:before{
                    color:$cta;
                }
            }
        }
    }
    
.cta_full, .cta_half{
    @include media-breakpoint-down(md){
        .text-center{
            text-align: left!important;
        }
    img{
        max-width: 200px;
        width: 50%;
    }
}
}

.cta-sticky{
    .cta-btn{
        font-size: $font-size-base*1.15!important;
    }
    .cta-arrow{
        width: 32px!important;
        height: 32px!important;
        i{
            font-size: 32px!important;
            line-height: 32px!important;
        }
    }
}

.cta-panel{
    @include media-breakpoint-down(xl){
        font-size: $font-size-sm;
    }
}

.bg-cta{
    .alert.bg-premier{
        background: $primary!important;
    }
}   