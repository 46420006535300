@import "../../../variables";

.our-tech {
    &-heading {
        @include font-size(2.813rem);
        margin-bottom: 30px;
        font-family: $headings-font-family;
        line-height: 1.2;
        
        &-sub {
            @include font-size(1.563rem);
            line-height: 1.35;
        }
    }

    &-body {
        background:url('./images/homepage-panel-circles-mobile.png') no-repeat bottom/contain;
        @include media-breakpoint-up(lg) {
            background:url('./images/homepage-panel-circles-desktop.png') no-repeat right/contain;
        }
        &:hover {
            .icon-arrow-right {
                transform: translatex(10px);
            }
        }
    }

    &-image {
        
        width: 399px;
        object-fit: contain;
        aspect-ratio: 0.87;

        @include media-breakpoint-up(lg) {
            margin-top: -6rem !important;
        }
        @include media-breakpoint-up(md) {
            margin-top: -3rem;
        }
    }
}

