@import "../../variables";

.spp-report{
    text-decoration: none!important;
    .arrow-link:after{
        transition: all 0.3s ease-in-out;
        background:$gold;
    }
    &:hover{
        color:inherit!important;
        .arrow-link:after{
            transform: translateX(10px);
        }
    }
}
