@import "../../variables";

table {
    @extend .table;
    &.tablepress {
        width: 100%!important;
        border-collapse: collapse;
        border-style: hidden;
        //table-layout: fixed;
        td, th {
            min-width: 4rem;
            border: 2px solid $primary;
            white-space: nowrap!important;
        }
        
        &.tablepress-sticky-column{
            .column-1 {
                position: sticky;
                left: 0;
                background: $tablehead!important;
                z-index: 1;
                @include media-breakpoint-down(sm){
                    width: 100px!important;
                }
               
            }
        } 
        &.tablepress-double-merged-header{
            thead{
                .column-1{
                    background: $secondary!important;
                }
            }
            thead + tbody > tr:first-child{
                background-color: $tablehead!important;
                > td{
                    min-width: 4rem;
                    border: 2px solid $primary;
                    white-space: nowrap!important;
                    font-weight: $font-weight-bold;
                }
            }
        }
        &.tablepress-text-center{
            tbody tr td:not(.column-1){
                text-align: center!important;
            }
        }

        &-sipp {
            &-download {
                @extend .premier-btn;
                @extend .text-decoration-none;
                @extend .mt-auto;
                @extend .py-2;
                @extend .px-3;
                @extend .rounded-pill;
            }
            tbody tr td {
                @extend .py-3;
            }
        }

        thead, tfoot {
           border-bottom: 1.5px solid $primary;
            tr {
                background-color: $tablehead!important;
            }
        }

        &:not(.tg) {
            tr {
                td:not(:first-child), th:not(:first-child) {
                    text-align: center;
                }
            }
        }
        tr:last-child{
            td{
                border-bottom: none!important;
            }
        }
    }
}

.tablepress-table-description {
    @extend .small;
}

.dataTables_filter {
    display: none;
}

.fixedHeader-floating,.fixedHeader-locked {
    background-color: $secondary !important;
}

.fixedHeader-floating {
    @include media-breakpoint-down(lg) { 
        display: none;
    }
}

.dataTables_wrapper.no-footer .dataTables_scrollBody{
    border: none;
}

.tablepress tfoot th, .tablepress thead th, .tablepress .even td, .tablepress .odd td{
    background-color: transparent!important;
}


.sipp-table{
	display: none;
	opacity: 0;
    .tablepress{
        td a{
            //@extend .premier-btn;
            cursor: pointer;
            @include transition($nav-link-transition);
            background-color: $premier;
            color: $secondary;
            display: inline-block;
            @extend  .text-decoration-none;
            @extend .mt-auto;
            @extend .py-2;
            @extend .px-3;
            @extend .rounded-pill;
            &:hover{
                background-color: $primary!important;
                color: $secondary!important;
            }
        }
    }
}

.tablepress-scrollable{
	overflow-x: auto;
    width: 100%;
}
