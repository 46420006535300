@use "sass:math";

// change font import
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

//////////////////////////////// BOOTSTRAP ////////////////////////////////

@import "./variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";


// create responsive versions of position classes
$utilities: map-merge($utilities, ("position": map-merge(map-get($utilities, "position"),
				(responsive: true),
			),
			"width": map-merge(map-get($utilities, "width"),
				(values: map-merge(map-get(map-get($utilities, "width"), "values"),
						(20: 20%, 35: 35%, 70: 70%, 80: 80%),
					),
				),
			),
			"height": map-merge(map-get($utilities, "height"),
				(values: map-merge(map-get(map-get($utilities, "height"), "values"),
						(90: 90%),
					),
				),
			),
			"viewport-height": map-merge(map-get($utilities, "viewport-height"),
				(values: map-merge(map-get(map-get($utilities, "viewport-height"), "values"),
						(80: 80vh),
					),
				),
			),
			"background-color": (property: background-color,
				class: bg,
				local-vars: ("bg-opacity": 1),
				values: map-merge($utilities-bg-colors,
					("transparent": transparent))),
			"color": (property: color,
				class: text,
				values: map-merge($theme-colors,
					$brand-colors )),
			"border-color": (property: border-color,
				class: border,
				values: map-merge($theme-colors,
					$brand-colors )),
			"opacity": (property: opacity,
				class: opacity,
				values: (0: 0,
					100: 100),
				responsive: true),
			"bg-opacity": (css-var: true,
				class: bg-opacity,
				values: (1:0.01,
					2:0.02,
					3:0.03,
					4:0.04,
					5:0.05,
					6:0.06,
					7:0.07,
					8:0.08,
					9:0.09,
					10:0.1,
					11:0.11,
					12:0.12,
					13:0.13,
					14:0.14,
					15:0.15,
					16:0.16,
					17:0.17,
					18:0.18,
					19:0.19,
					20:0.2,
					21:0.21,
					22:0.22,
					23:0.23,
					24:0.24,
					25:0.25,
					26:0.26,
					27:0.27,
					28:0.28,
					29:0.29,
					30:0.3,
					31:0.31,
					32:0.32,
					33:0.33,
					34:0.34,
					35:0.35,
					36:0.36,
					37:0.37,
					38:0.38,
					39:0.39,
					40:0.4,
					41:0.41,
					42:0.42,
					43:0.43,
					44:0.44,
					45:0.45,
					46:0.46,
					47:0.47,
					48:0.48,
					49:0.49,
					50:0.5,
					51:0.51,
					52:0.52,
					53:0.53,
					54:0.54,
					55:0.55,
					56:0.56,
					57:0.57,
					58:0.58,
					59:0.59,
					60:0.6,
					61:0.61,
					62:0.62,
					63:0.63,
					64:0.64,
					65:0.65,
					66:0.66,
					67:0.67,
					68:0.68,
					69:0.69,
					70:0.7,
					71:0.71,
					72:0.72,
					73:0.73,
					74:0.74,
					75:0.75,
					76:0.76,
					77:0.77,
					78:0.78,
					79:0.79,
					80:0.8,
					81:0.81,
					82:0.82,
					83:0.83,
					84:0.84,
					85:0.85,
					86:0.86,
					87:0.87,
					88:0.88,
					89:0.89,
					90:0.9,
					91:0.91,
					92:0.92,
					93:0.93,
					94:0.94,
					95:0.95,
					96:0.96,
					97:0.97,
					98:0.98,
					99:0.99,
					100:1))));


@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";

// HELPERS
@import "bootstrap/scss/helpers";

// UTILITIES
@import "bootstrap/scss/utilities/api";

@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';

// COMPONENTS
@import "scss/base/utilities";
@import "scss/menu";
@import "scss/components/icons";
@import "scss/components/table";
@import "scss/homepage";
@import "scss/tabs";
@import "scss/components/image";
@import "scss/footer";
@import "scss/contact";
@import "scss/components/button";
@import "scss/components/team";
@import "scss/components/accordion";
@import "scss/components/webinar";
@import "scss/components/carousel";
@import "scss/components/animations";
@import "scss/components/modal";
@import "scss/components/form";
@import "scss/components/video";
@import "scss/components/homepage/our_tech";
@import "scss/components/homepage/chartered_status";
@import "scss/typography/typography";
@import "scss/pages/blog";
@import "scss/pages/portal";
@import "scss/pages/wealth-index/page";
@import "scss/pages/wealth-index/landing";
@import "scss/pages/why-saltus";
@import "scss/pages/spp";
@import "scss/careers";
@import "scss/spinner";
@import "scss/components/charts";
@import "scss/components/panel_links";
@import "scss/components/cta";
@import "scss/components/stats";
@import "scss/components/location";
@import "scss/components/cards";
@import "scss/components/search";
@import "scss/components/pull_quote";
@import "scss/components/office_location";

//////////////////////////////// SITE STYLES ////////////////////////////////

html{
	overflow-x: hidden;
}


// TODO: 
// h1,h2,h3,h4,h5,h6, .h1,.h2,.h3,.h4,.h5,.h6{
//   letter-spacing: $headings-letter-spacing;
// }

.mt-header-height {
	margin-top: $header-height;

	// &.modal-body {
	// 	margin-top: $header-height + ($spacer * 1.5)
	// }
	@include media-breakpoint-down(lg) {
		// affects md and smaller
		margin-top: $header-height-mobile;
	}
}

.overflow-x-clip{
	overflow-x: clip!important;
}

main.mt-header-height {

	&> :first-child,
	&> :first-child.d-none+* {
		padding-top: $spacer * 1.5;
	}
	&> :first-child.my-5{
		margin-top: 0!important;
	}

}

.top-header-height {
	top: $header-height + ($spacer * 1.5);
}

.deep-header-height {
	top: $header-height + $sticky-nav-height + ($spacer * 1.5);
}

.mt-negative {
	margin-top: -$header-height; //*0.66 removed

	@include media-breakpoint-down(lg) {
		// affects md and smaller
		margin-top: -$header-height-mobile;
	}
}

.scroll-target {
	scroll-snap-margin-top: $header-height;
	scroll-margin-top: $header-height;
}

.me-extra {
	margin-right: $spacer*4;
}

// Utility
.line {
	display: block;

	&__shorter {
		width: 3%;
	}

	&__short {
		width: 5%;
	}

	&__med {
		width: 10%;
	}

	&__long {
		width: 100%;
	}

	&__dark {
		border-bottom: 1px solid $primary;
	}
	&__dust,&__light {
		border-bottom: 1px solid $secondary;
	}

	&__gold {
		border-bottom: 1px solid $gold;
	}

	&__premier {
		border-bottom: 1px solid $premier;
	}

	&__private {
		border-bottom: 1px solid $private;
	}

	&__lorica {
		border-bottom: 1px solid $lorica;
	}

	&__asset {
		border-bottom: 1px solid $asset;
	}
}

.video-template {
	p {
		@extend .mb-0;
	}

	.line__short {
		width: 2rem !important;
	}
}


.wi-landing {
	.line__short {
		width: 2rem;
	}
}

// automatically add the lines dividing li on text blocks
// add styled links to em > a
.text-container {
	ul {
		margin-left: 0;
		padding-left: 0;
		border-top: 1px solid $primary;

		li {
			border-bottom: 1px solid $primary;
			list-style: none;
		}

		li:not(.nav-item) {
			padding: $spacer 0;
		}
	}

	&.text-secondary {
		ul {
			border-top: 1px solid $secondary;

			li {
				border-bottom: 1px solid $secondary;
			}
		}
	}

	em a {
		//font-size: $h5-font-size;
		font-family: $headings-font-family;
		font-style: normal !important;
		text-decoration: none;
		@extend .arrow-link;
		justify-content: left;
		@include transition($nav-link-transition);

		&::after {
			height: 1rem;
			width: 1rem;
			margin-left: $spacer;
			background: $premier; // default
		}

		&:hover {
			color: $premier; // default
		}
	}

	:last-child {
		margin-bottom: 0 !important; // remove the bottom margin at the end of a text container
	}

	&-columns {
		@include media-breakpoint-up(md) {
			column-count: 2;
			column-gap: $grid-gutter-width;

			>* {
				display: inline-block;
			}
		}
	}
}
.premier {

	a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact), .a:not(.office-tile) {

		&:hover,
		&:active,
		&:focus {
			color: $premier !important;

			&.arrow-link:after {
				background: $premier !important;
			}
		}
	}

	&.nav-area {
		.nav-link {

			&:hover,
			&.active {
				color: $premier !important;

				&:after {
					background: $premier !important;
				}
			}
		}

		.navbar-contact,
		.navbar-login {

			&:hover,
			&.active {
				&:before {
					background: $premier !important;
				}
			}
		}
	}

	&.header {

		.nav-link:not(.navbar-contact),
		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				color: $premier !important;
			}
		}

		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				border-color: $premier !important;

				&:before {
					background: $premier !important;
				}
			}
		}

		.nav-lower,
		.nav-tabs .nav-link.active {
			border-color: $premier;
		}
	}

	.navbar-brand {

		&:hover,
		&:active {
			background: $premier !important;
		}
	}

	.text-secondary {
		a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
			&:hover {
				color: $premier;
			}
		}
		&.bg-premier,&.bg-lorica,&.bg-asset,&.bg-lorica,&.bg-gold,&.bg-private{
			a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
				&:hover {
					color: $secondary!important;
				}
			}
		}
	}

	.text-container {
		em a {
			&::after {
				background: $premier;
			}

			&:hover {
				color: $premier;
			}
		}
	}

	.wealth-index-sticky .nav-link.active {
		color: $premier !important;
	}

	.col-panel {
		.btn-primary {
			&:hover {
				background-color: $premier;
				border-color: $premier;
			}
		}

	}

	.premier-on-hover {
		&:hover {
			border-bottom-color: $premier !important;
		}
	}

	.swiper-pagination-bullet-active{
		background-color:$premier!important;
	}

	.faq-accordion-header {
		&:hover {
			.plus-to-cross.collapsed {
				&::after {
					background: $premier !important;
				}
			}
		}
	}
	&.blog-post-tile{
		&:hover {
			color: $premier !important;
			.post-list-title {
				color: $premier !important;
			}
		}
	}
	lite-vimeo:not(.lite-vimeo-primary):hover > .ltv-playbtn{
		background: $premier !important;
	} 
	lite-youtube:not(.lite-youtube-primary):hover > .lty-playbtn{
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(240, 100, 90)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
	}
	.team-tab.nav-link {
		@include media-breakpoint-down(lg) {
			&.active {
				background-color: $premier!important;
				color: $secondary!important;
			}
		}
	}
	
}
.lorica {

	a:not([class*="btn"]):not(.navbar-contact),
	.nav-link:not(.navbar-contact) {

		&:hover,
		&:active,
		&:focus {
			color: $lorica !important;

			&.arrow-link:after {
				background: $lorica !important;
			}
		}
	}

	&.nav-area {
		.nav-link {

			&:hover,
			&.active {
				color: $lorica !important;

				&:after {
					background: $lorica !important;
				}
			}
		}

		.navbar-contact,
		.navbar-login {

			&:hover,
			&.active {
				&:before {
					background: $lorica !important;
				}
			}
		}
	}

	&.header {

		.nav-link:not(.navbar-contact),
		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				color: $lorica !important;
			}
		}

		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				border-color: $lorica !important;

				&:before {
					background: $lorica !important;
				}
			}
		}

		.nav-lower,
		.nav-tabs .nav-link.active {
			border-color: $lorica;
		}
	}

	.navbar-brand {

		&:hover,
		&:active {
			background: $lorica !important;
		}
	}

	.text-container {
		em a {
			&::after {
				background: $lorica;
			}

			&:hover {
				color: $lorica;
			}
		}
	}

	.text-secondary {
		a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
			&:hover {
				color: $lorica;
			}
		}
		&.bg-premier,&.bg-lorica,&.bg-asset,&.bg-lorica,&.bg-gold,&.bg-private{
			a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
				&:hover {
					color: $secondary!important;
				}
			}
		}
	}

	.wealth-index-sticky .nav-link.active {
		color: $lorica !important;
	}

	.col-panel {
		.btn-primary {
			&:hover {
				background-color: $lorica;
				border-color: $lorica;
			}
		}
	}

	.premier-on-hover {
		&:hover {
			border-bottom-color: $lorica !important;
		}
	}

	.swiper-pagination-bullet-active{
		background-color:$lorica!important;
	}

	.faq-accordion-header {
		&:hover {
			.plus-to-cross.collapsed {
				&::after {
					background: $lorica !important;
				}
			}
		}
	}
	&.blog-post-tile{
		&:hover {
			color: $lorica !important;
			.post-list-title {
				color: $lorica !important;
			}
		}
	}
	lite-vimeo:not(.lite-vimeo-primary):hover > .ltv-playbtn{
		background: $lorica !important;
	} 
	lite-youtube:not(.lite-youtube-primary):hover > .lty-playbtn{
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(176, 79, 125)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
	}
	.team-tab.nav-link {
		@include media-breakpoint-down(lg) {
			&.active {
				background-color: $lorica!important;
				color: $secondary!important;
			}
		}
	}
}

.asset {

	a:not([class*="btn"]):not(.navbar-contact),
	.nav-link:not(.navbar-contact) {

		&:hover,
		&:active,
		&:focus {
			color: $asset !important;

			&.arrow-link:after {
				background: $asset !important;
			}
		}
	}

	&.nav-area {
		.nav-link {

			&:hover,
			&.active {
				color: $asset !important;

				&:after {
					background: $asset !important;
				}
			}
		}

		.navbar-contact,
		.navbar-login {

			&:hover,
			&.active {
				&:before {
					background: $asset !important;
				}
			}
		}
	}

	&.header {

		.nav-link:not(.navbar-contact),
		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				color: $asset !important;
			}
		}

		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				border-color: $asset !important;

				&:before {
					background: $asset !important;
				}
			}
		}

		.nav-lower,
		.nav-tabs .nav-link.active {
			border-color: $asset;
		}
	}

	.navbar-brand {

		&:hover,
		&:active {
			background: $asset !important;
		}
	}

	.text-container {
		em a {
			&::after {
				background: $asset;
			}

			&:hover {
				color: $asset;
			}
		}
	}

	.text-secondary {
		a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
			&:hover {
				color: $asset;
			}
		}
		&.bg-premier,&.bg-lorica,&.bg-asset,&.bg-lorica,&.bg-gold,&.bg-private{
			a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
				&:hover {
					color: $secondary!important;
				}
			}
		}
	}

	.wealth-index-sticky .nav-link.active {
		color: $asset !important;
	}

	.col-panel {
		.btn-primary {
			&:hover {
				background-color: $asset;
				border-color: $asset;
			}
		}
	}

	.premier-on-hover {
		&:hover {
			border-bottom-color: $asset !important;
		}
	}

	.swiper-pagination-bullet-active{
		background-color:$asset!important;
	}

	.faq-accordion-header {
		&:hover {
			.plus-to-cross.collapsed {
				&::after {
					background: $asset !important;
				}
			}
		}
	}
	&.blog-post-tile{
		&:hover {
			color: $asset !important;
			.post-list-title {
				color: $asset !important;
			}
		}
	}
	lite-vimeo:not(.lite-vimeo-primary):hover > .ltv-playbtn{
		background: $asset !important;
	} 
	lite-youtube:not(.lite-youtube-primary):hover > .lty-playbtn{
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(56, 121, 178)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
	}

	.team-tab.nav-link {
		@include media-breakpoint-down(lg) {
			&.active {
				background-color: $asset!important;
				color: $secondary!important;
			}
		}
	}
}

.private {

	a:not([class*="btn"]):not(.navbar-contact),
	.nav-link:not(.navbar-contact) {

		&:hover,
		&:active,
		&:focus {
			color: $private !important;

			&.arrow-link:after {
				background: $private !important;
			}
		}
	}

	&.nav-area {
		.nav-link {

			&:hover,
			&.active {
				color: $private !important;

				&:after {
					background: $private !important;
				}
			}
		}

		.navbar-contact,
		.navbar-login {

			&:hover,
			&.active {
				&:before {
					background: $private !important;
				}
			}
		}
	}

	&.header {

		.nav-link:not(.navbar-contact),
		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				color: $private !important;
			}
		}

		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				border-color: $private !important;

				&:before {
					background: $private !important;
				}
			}
		}

		.nav-lower,
		.nav-tabs .nav-link.active {
			border-color: $private;
		}
	}

	.navbar-brand {

		&:hover,
		&:active {
			background: $private !important;
		}
	}

	.text-container {
		em a {
			&::after {
				background: $private;
			}

			&:hover {
				color: $private;
			}
		}
	}

	.text-secondary {
		a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
			&:hover {
				color: $private;
			}
		}
		&.bg-premier,&.bg-lorica,&.bg-asset,&.bg-lorica,&.bg-gold,&.bg-private{
			a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
				&:hover {
					color: $secondary!important;
				}
			}
		}
	}

	.wealth-index-sticky .nav-link.active {
		color: $private !important;
	}

	.col-panel {
		.btn-primary {
			&:hover {
				background-color: $private;
				border-color: $private;
			}
		}
	}

	.premier-on-hover {
		&:hover {
			border-bottom-color: $private !important;
		}
	}

	.swiper-pagination-bullet-active{
		background-color:$private!important;
	}

	.faq-accordion-header {
		&:hover {
			.plus-to-cross.collapsed {
				&::after {
					background: $private !important;
				}
			}
		}
	}
	.vertical-line {
		border-left: 2px solid $private!important;
	}
	&.blog-post-tile{
		&:hover {
			color: $private !important;
			.post-list-title {
				color: $private !important;
			}
		}
	}
	lite-vimeo:not(.lite-vimeo-primary):hover > .ltv-playbtn{
		background: $private !important;
	}
	lite-youtube:not(.lite-youtube-primary):hover > .lty-playbtn{
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(73, 121, 107)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
	}

	.team-tab.nav-link {
		@include media-breakpoint-down(lg) {
			&.active {
				background-color: $private!important;
				color: $secondary!important;
			}
		}
	}
}

.gold {

	a:not(.contact-card-link):not([class*="btn"]):not(.navbar-contact),
	.nav-link:not(.navbar-contact) {

		&:hover,
		&:active,
		&:focus {
			color: $gold !important;

			&.arrow-link:after {
				background: $gold !important;
			}
		}
	}

	&.nav-area {
		.nav-link {

			&:hover,
			&.active {
				color: $gold !important;

				&:after {
					background: $gold !important;
				}
			}
		}

		.navbar-contact,
		.navbar-login {

			&:hover,
			&.active {
				&:before {
					background: $gold !important;
				}
			}
		}
	}

	&.header {

		.nav-link:not(.navbar-contact),
		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				color: $gold !important;
			}
		}

		.navbar-nav .btn.rounded-pill {

			&:hover,
			&.active {
				border-color: $gold !important;

				&:before {
					background: $gold !important;
				}
			}
		}

		.nav-lower,
		.nav-tabs .nav-link.active {
			border-color: $gold;
		}
	}

	.navbar-brand {

		&:hover,
		&:active {
			background: $gold !important;
		}
	}

	a.text-primary {

		&:hover,
		&:active {
			color: $primary !important;
		}
	}

	.text-container {
		em a {
			&::after {
				background: $gold;
			}

			&:hover {
				color: $gold;
			}
		}
	}

	.text-secondary {
		a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
			&:hover {
				color: $gold;
			}
		}
		&.bg-premier,&.bg-lorica,&.bg-asset,&.bg-gold,&.bg-private{
			a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
				&:hover {
					color: $secondary!important;
				}
			}
		}
	}

	.wealth-index-sticky .nav-link.active {
		color: $gold !important;
	}

	.col-panel {
		.btn-primary {
			&:hover {
				background-color: $gold;
				border-color: $gold;
				color: $primary !important;

				&:after {
					background: $primary !important;
				}
			}
		}
	}

	.premier-on-hover {
		&:hover {
			border-bottom-color: $gold !important;
		}
	}

	.swiper-pagination-bullet-active{
		background-color:$gold!important;
	}

	.faq-accordion-header {
		&:hover {
			.plus-to-cross.collapsed {
				&::after {
					background: $gold !important;
				}
			}
		}
	}
	&.blog-post-tile{
		&:hover {
			color: $gold !important;
			.post-list-title {
				color: $gold !important;
			}
		}
	}
	lite-vimeo:not(.lite-vimeo-primary):hover > .ltv-playbtn{
		background: $gold !important;
	} 
	lite-youtube:not(.lite-youtube-primary):hover > .lty-playbtn{
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="rgb(236, 159, 34)"/><path d="M45 24 27 14v20" fill="white"/></svg>')!important;
	}

	.team-tab.nav-link {
		@include media-breakpoint-down(lg) {
			&.active {
				background-color: $gold!important;
				color: $primary!important;
			}
		}
	}
}



.navbar-dark .navbar-toggler-icon,
.accordion-button .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255,245,230%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.nav-link:not(.navbar-contact) {

	&:hover,
	&:active {
		color: $premier !important;

		&.arrow-link:after {
			background: $premier !important;
		}
	}
}

.menu-item {

	// &.current-page-ancestor,&.current-menu-item{
	// 	> .nav-link {
	// 		&:hover,
	// 		&:active {
	// 			color: $premier !important;
	// 		}
	// 	}
	// }
	>.nav-link {

		&:hover,
		&:active {
			color: $secondary !important;

			&.arrow-link:after {
				background: $secondary !important;
			}
		}

		&.text-dark {

			&:hover,
			&:active {
				color: $dark !important;

				&.arrow-link:after {
					background: $dark !important;
				}
			}
		}
	}
}

// Bio Modal 
.bio-image {
	position: fixed;
	top: 8rem;
	left: 9.5rem;
	right: 0;
}

// min-height
.min-vh-100 {
	min-height: 100vh;
}

// lorica overrides
body.lorica {
	background-color: $white;

	section {

		.text-secondary,
		.text-dust {
			color: $white !important;
		}
	}
}

blockquote {
	font-family: $headings-font-family;
	font-size: $h3-font-size;
	line-height: $headings-line-height;
	margin-top: $spacer * 2;
	margin-bottom: $spacer * 2;
}

.premier {
	blockquote {
		color: $midblue;
	}

	.eat-citation {
		color: $premier;
	}

}

.private {

	blockquote,
	.eat-citation,
	.profile-more-inner a:hover,
	.profile-more .arrow-link-down:hover {
		color: $private !important;
	}

	.profile-more .arrow-link-down:hover::after {
		background: $private !important;
	}
}

// btns for all theme colours
@each $color, $value in $brand-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

@each $color, $value in $brand-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
	}
}

// override the text colour to dust
.btn-dark {
	color: $secondary;
}

.z-index-top {
	position: relative;
	z-index: 1;
}

.h1-form {
	font-size: $font-size-base * 2.2;
}

.toggle-logo {
	@include media-breakpoint-up(lg) {
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s;
	}
}

.toggle-text {
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s;
}

.scrolled-text {
	.toggle-text {
		visibility: visible;
		opacity: 1;
	}
}

.scrolled-logo {
	.toggle-logo {
		visibility: visible;
		opacity: 1;
	}
}

.table {

	td,
	th {
		min-width: 4rem;
	}
}


.table-responsive table {
	@include media-breakpoint-down(md) {
		min-width: 800px;
	}
}

.insights {
	h2 {
		@extend .h4;
	}

	h3 {
		@extend .h5;
	}
}

.grid {
	display: -ms-grid;
	display: grid;

	&-col-2 {
		grid-gap: 1rem;
		grid-template-columns: repeat(2, 2fr);

		@include media-breakpoint-down(sm) {
			// affects md and smaller
			grid-template-columns: repeat(1, 2fr);
		}
	}

	&-about-us {
		grid-template-columns: repeat(4, auto);
	}
}

.awards-grid {
	&-inner {
		max-width: 18rem;

		img {
			max-width: 14rem;
		}

		&-text {
			max-width: 20rem;
		}

		&-three {
			flex: 1 0 33%;
		}

		&-four {
			flex: 1 0 25%;
		}

		@include media-breakpoint-down(lg) {
			flex: 1 0 60%;
		}
	}

	&-image {
		max-width: 13rem;
		max-height: 13rem;
		width: 100%;

		span {
			display: block;
			height: 0;
			padding-bottom: 100%;
			//background-color: yellow;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}


// rotation utility class
.rotate-90 {
	display: block;
	transform: rotate(90deg);
}

.rotate-180 {
	display: block;
	transform: rotate(180deg);
}

.rotate-270 {
	display: block;
	transform: rotate(270deg);
}


//tooltip

.tooltip-inner {
	@extend .shadow;
	border-radius: $tooltip-border-radius;
	position: relative;
	z-index: 1;
}

.tooltip-arrow {
	border-right-color: $tooltip-bg !important;
}

.tooltip-arrow::after {
	content: "";
	position: absolute;
	left: 50%;
	z-index: -1;
	border: $spacer*0.75 solid $tooltip-bg ;
	transform-origin: 0 0;
	transform: rotate(45deg);
	@extend .shadow;
}

.contact-card-link {
	text-decoration: none;

	&:hover,
	&:active {
		color: $primary !important;
	}
}

//override theme colours

.hero-banner{
	a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
		&:hover{
			color: $midblue!important;
		}
	}
	.share{
		&:hover{
			background: $midblue!important;
		}
	}
}

.footer, .webinar-card{
	a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
        &:hover{
            color:$secondary!important;
        }
    }
}

.menu-item{
	a:not([class*="btn"]):not(.navbar-contact):not([class*="rounded-pill"]),
	.nav-link:not(.navbar-contact) {
		&:hover,
		&:active,
		&:focus {
			color: $secondary !important;

			&.arrow-link:after {
				background: $secondary !important;
			}
		}
	}
}

// end override theme colours