@import "../../variables";

$blog-h2-size: 1.563rem;
$blog-h3-size: 1.375rem;
$blog-h4-size: 1.125rem;

.video-cta {
    cursor: pointer;
    color: $secondary;
    .arrow-link-up {
        &.premier {
            &::after {
                background: $premier;
                @extend .icon-lg;
                cursor: pointer;
            }
        }
        &.private {
            &::after {
                background: $private;
                @extend .icon-lg;
                cursor: pointer;
            }
        }
        &.gold {
            &::after {
                background: $gold;
                @extend .icon-lg;
                cursor: pointer;
            }
        }
    }
    &:hover {
        .arrow-link-up {
            &::after {
                transform: translateY(-10px) rotate(-90deg);
            }
        }
    }

    p:not(:first-child), ul {
        display:none;
    }

    .contact-disclaimer {
        @extend .col-lg-6;
        @extend .ms-lg-auto;
        @include media-breakpoint-up(lg) {
            margin-top: -2rem !important;
            padding-left: 1rem;
        }
    }
    
    &-mt8 {
        .contact-disclaimer {
            @include media-breakpoint-up(lg) {
                margin-top: -5rem !important;
            }
        }
    }

    button {
        border-color: $secondary;
    }

    form, .video-text {
        display: none;
    }

    
    &.blog-form {
        
        form {
            display: block;
            .form-control, .form-select {
                background-color: $midblue;
            }
        }
        .video-text {
            display: block;
        }

        .arrow-link-up {
            &::after {
                background: $secondary;
                -webkit-mask-image: url("images/close.svg");
                mask-image: url("images/close.svg");
            }
        }
        .arrow-link-up {
            @include transition($nav-link-transition);
            &::after {
                transform: none !important;
                transition: none !important;
            }
        }
        &:hover {
            h4 {
                color: $secondary;
            }
            .arrow-link-up {
                &::after {
                    transform: none !important;
                    transition: none !important;
                }
            }
        }
        .alert{
            @extend .mx-4;
            @extend .my-4;
        }
    }
}

#form-toggle {
    cursor: pointer;
    .arrow-link-up {
        &:hover {
            &::after {

                background-color: $premier;
            }
        }
    }
}

.blog {
    &:not(.wealth-index-text){
        h2, h3, h4 {
            padding-top: 1.5rem;
            line-height: 1.4;
        }

        ul, ol {
            list-style: none; /* Remove default bullets */
            padding-left: 1.5rem;
          }
          
          ol{
            padding-left: 1.5rem;
            &:not([start]){
                counter-reset: li;
            }
          }
          li {counter-increment: li;
            margin-bottom: 0.25rem;}
          ul li::before,  ol li::before {
            color: $premier; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
          

          ol li:nth-child(n+10)::before{
            width: 1.5em; /* Also needed for space (tweak if needed) */
            margin-left: -1.5em; /* Also needed for space (tweak if needed) */
          }

          ul li::before{
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */

          }
          ol li::before{
            content: counter(li);

          }
          ol[start]{
            li::before{
                content: counter(lis) " ";
    
              }
          }
          

          h3{
            margin-bottom: 1.5rem;
          }

        // h4, .h4 {
        //     font-size: $blog-h4-size;
        // }
        // h2 {
        //     font-size: $blog-h2-size;
        // }
        // h3 {
        //     font-size: $blog-h3-size;
        // }
    }

    &.bullet {
        padding-left:30px;
        h4, .h4 {
            position: relative;
            margin-bottom: 0 !important;
            &:before {
                content: '';
                background: url('images/bullet.png');
                width: 22px;
                height: 22px;
                position: absolute;
                left: -30px;
                top: 25px;
                background-size: 25px;
                background-repeat: no-repeat;
            }
            @include media-breakpoint-down(lg) {
                &:before {
                    left: -30px;
                    top: 27px;
                    background-size: 22px;
                }
            }
        }
    }

    &-cta {
        a {
            @include transition($nav-link-transition);
            color: $secondary;
            &:hover {
                color: $tertiary;
                background: $secondary !important;
            }
        }
    }

    &-form {
        top: 0;
        height: 100%;
        width: 100%;
        visibility: visible !important;
        margin-top: 0 !important;
        cursor: auto;
    }

    &-author {
        &-image {
            //width: 11%;
            width: 90px;
            @include media-breakpoint-down(md) {
                //width: 17%;
                width:70px;

            }
        }
    }

    &-text {
        .quote {
            p {
                @extend .h4;
            }
        }

        .disclaimer {
            p {
                @extend .small;
            }
        }
    }

    .guide-image {
        max-width: 127px;
        object-fit: contain;
    }
}

@for $i from 1 through 101 {
    ol[start="#{$i}"] {
      counter-reset: lis #{$i};
    }
    
  }

.private{
    .blog{
        ul li::before, ol li::before {
            color: $private; /* Change the color */
          }
    }
}

.author-body{
    a{
        @include media-breakpoint-down(sm) {
            display: block!important;
        }
    }
}

.header-sticky {
    @include transition($nav-link-transition);
    &-hide {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
    }
    &-show {
        max-height: auto;
        opacity: 1;
        visibility: visible;
    }
    &-aau {
        position: -webkit-sticky;
        position: sticky;
        top: 123px;
        z-index: 1;
        &-mobile {
            top: 90px !important;
            .accordion-button {
                display: flex;
                align-items: center;
                &::after {
                    content: '';
                    display: block;
                    background: $secondary;
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-left: auto;
                    -webkit-mask-image: url("images/accordion-icon-dust.svg");
                    mask-image: url("images/accordion-icon-dust.svg");
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-position: top;
                    mask-position: top;
                    -webkit-mask-size: contain;
                    mask-size: contain;
                    @include transition($nav-link-transition);
                }
            }
        }
        p {
            margin-bottom: 0;
        }
        .nav-link {
            border-bottom: 1px solid transparent;
            &:hover, &:active {
                color: $secondary !important;
                border-bottom: 2px solid #fff;
            }
            &.active {
                border-bottom: 2px solid #fff;
            }
        }
    }
}

.highlighted-awards, .video-cta {
    h3, h4 {
        @include transition($nav-link-transition);
    }

    &:hover {
        h3, h4 {
            color: $premier;
        }
    }
}

.video-cta {
    &:hover {
        &.private {
            h3, h4 {
                color: $private;
            }
            &.blog-form {
                h3, h4 {
                    color: $secondary;
                }
            }
        }
        &.gold {
            h3, h4 {
                color: $gold;
            }
            &.blog-form {
                h3, h4 {
                    color: $gold;
                }
            }
        }
    }
}



.dust-cta {
    cursor: pointer;
   
    h5 {
        @include transition($nav-link-transition);
        color: $primary;
    }
    &-private {
        &:hover {
            h5 {
                color: $private;
            }
        }
    }

    &-premier {
        &:hover {
            h5 {
                color: $premier;
            }
        }
    }

}

.premier-cta, .guide-btn {
    cursor: pointer;
    a {
        @include transition($nav-link-transition);
        background-color: $primary;
        color: $secondary;
    }
    &:hover {
        a {
            background-color: $secondary;
            color: $primary;
        }
    }

}

// Asset Allocation

.anchor {
    top: -165px;
    display: block;
    position: relative;
    // visibility: hidden;
    @include media-breakpoint-down(lg) {
        top: -518px;
    }
}


$aa-title-height: 110px;

.asset-allocation {
    &-title {
        height: $aa-title-height;
        img {
            object-fit: contain;
            height: 100%;
        }
        
        h2 {
            font-size: 40px !important;
            @include media-breakpoint-down(lg) {
                font-size: 35px !important;
            }
            @include media-breakpoint-down(md) {
                font-size: 30px !important;
            }
            @include media-breakpoint-down(sm) {
                font-size: 25px !important;
            }
        }
        .background {
            height: $aa-title-height;
            left: 0;
            width: 100vw;
            z-index: -1000;
        }
    }


    &-question::before {
        content: 'Q:';
    }

    &-answer::before  {
        content: 'A:';
    }

    &-question,
    &-answer {
        padding-left:25px;
        &::before {
            font-weight: bold;
            font-size: 18px;
            position: absolute;
            left: 0;
        }
    }

    &-author {
        a:hover {
            color: $private;
        }
    }

    &-table {
        margin: 1rem 0;
        table {
            width: 100%;
            border-collapse: collapse;
            border-style: hidden;
            td, th {
                border: 2px solid transparentize($primary, 0);
                white-space: nowrap;
            }
        
            thead, tfoot {
                border-bottom: 3px solid transparentize($primary, 0);
    
                tr {
                    background-color: transparentize($tertiary, 0.8);
                }
            }
    
            &:not(.tg) {
                tr {
                    td:not(:first-child), th:not(:first-child) {
                        text-align: center;
                    }
                }
            }
        }

        &-fixed{
            table-layout: fixed;
            td, th {
                white-space: initial!important;
            }
            min-width: 818px;
        }
        &-sticky-column{
            .column-1 {
                position: sticky;
                left: 0;
                background: $tablehead!important;
                z-index: 1;
                @include media-breakpoint-down(sm){
                    width: 100px!important;
                }
               
            }
        } 
    }
}

.q_and_a_author {
    // position: absolute;
    // left: -10rem;
    .team-profile {
        width: 8rem;
    }
    @include media-breakpoint-down(lg) {
        position: relative;
        left: initial;
    }
}

// table icons

.aau-arrow {
    position: relative;
    
    &-right:before {
        content: '';
        position: absolute;
        background: url('images/arrow-right.svg');
        width: 78px;
        height: 22px;
        left: -85px;
        background-size: 78px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: -70px;
            top: 1px;
            background-size: 62px;
        }
        @include media-breakpoint-down(sm) {
            left: -58px;
            top: 2px;
            background-size: 55px;
        }
    }

    &-right-longer:before {
        content: '';
        position: absolute;
        background: url('images/arrow-right-longer.svg');
        width: 150px;
        height: 22px;
        left: -140px;
        background-size: 140px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: -153px;
            background-size: 146px;
        }
        @include media-breakpoint-down(sm) {
            left: -134px;
            top: 0px;
            background-size: 130px;
            width: 150px;
        }
    }
    &-right-longer-3col:before {
        content: '';
        position: absolute;
        background: url('images/arrow-right-longer-3col.svg');
        width: 225px;
        height: 24px;
        right: 5px;
        background-size: 100%;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            right: -1px;
            width: 259px;
            background-size: 255px;
            height: 30px;
        }
        @include media-breakpoint-down(sm) {
            right: 5px;
            top: -2px;
            background-size: 230px;
            width: 230px;
        }
    }


    &-left:after {
        content: '';
        position: absolute;
        background: url('images/arrow-left.svg');
        width: 78px;
        height: 22px;
        left: 12px;
        background-size: 78px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: 7px;
            top: 0px;
            background-size: 62px;
        }
        @include media-breakpoint-down(sm) {
            left: 3px;
            top: 1px;
            background-size: 65px;
        }
    }

    &-left-longer:after {
        content: '';
        position: absolute;
        background: url('images/arrow-left-longer.svg');
        width: 140px;
        height: 24px;
        left: 12px;
        background-size: 140px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: 7px;
            top: -11%;
            background-size: 82%;
            width: 187px;
        }
        @include media-breakpoint-down(md) {
            left: 7px;
            top: -11%;
            background-size: 100%;
            width: 140px;
        }
    }

    &-left-longer-3col:after {
        content: '';
        position: absolute;
        background: url('images/arrow-left-longer-3col.svg');
        width: 225px;
        height: 24px;
        left: 8px;
        background-size: 225px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            left: 7px;
            top: -20%;
            background-size: 100%;
            width: 260px;
            height: 30px;
        }
        @include media-breakpoint-down(md) {
            left: 7px;
            top: -11%;
            width: 225px;
        }
    }
}

.form-select.data-table {
    @include media-breakpoint-down(sm) {
        width: 100% !important;
    }
}

.post-list {
    // img {
    //     // @include transition($nav-link-transition);
    //     transition: transform 500ms ease;
    //     cursor: pointer;
    //     transform: scale(1.01);
    //     &:hover {
    //         transform: scale(1.03);
    //         .post-list-title {
    //             color: $premier !important;
    //         }
    //     }
    // }

    &-title {
        @include transition($nav-link-transition);
    }
}

.blog-post-tile{
        img{
            transition: transform 500ms ease;
            cursor: pointer;
            transform: scale(1.01);
        }
        &:hover {
            img{
                transform: scale(1.03);
            }
            .post-list-title {
                color: $premier !important;
            }
        }
}

.guide-banner-image{
    @include media-breakpoint-up(lg) {
         margin-right:$logo-width;
      
    }

    height: 250px;
}

.container-xl .container-xl{
    padding-left: 0!important;
    padding-right: 0!important;
}

.eat-citation{
    color:$premier;
    text-decoration: none;
}


.single-new-content{
        display: block;
}