@import "../../../variables";

.chartered-status-wrapper{
    margin-top:40px!important;
    margin-bottom: 40px!important;
}

.chartered-status{
    img{
        margin:40px 80px;
        width: 160px;
    }
    .row > div > div{
        margin:40px 0 40px 40px;
        @include media-breakpoint-down(md) {
            margin:$spacer*2 $spacer*2 40px $spacer*2;
        }
    }
}

.chartered-panel{
    max-width: 70%;
    @include media-breakpoint-down(lg) {
        max-width: 45%;
    }
    @include media-breakpoint-down(md) {
        max-width: 60%;
    }
    @media (max-width: 425px) {
        max-width: 100%;
    }
    img{
        max-width: 60%;
        @include media-breakpoint-down(lg) {
            max-width: 40%;
        }
    }
}