@import "../../variables";

.notification-modal {
    
    background-color: rgba(24, 38, 58, .8);
    z-index: 15000;
    &-logo {
        height: 10rem;
        &-hfa {
            width: 15rem;
            background: url("images/higgins-fairbain-logo-dust.jpg") no-repeat center/contain;
        }
        &-bj {
            width: 18rem;
            background: url("images/beckford-james-logo.png") no-repeat center/contain;
            @include media-breakpoint-down(md){
                width: 12rem;
            }
            @include media-breakpoint-down(md){
                width: 15rem;
                height: 5rem;
            }
        }
        &-bjs {
            width: 18rem;
            background: url("images/Beckford_Saltus_Logo_Coral.png") no-repeat center/contain;
            @include media-breakpoint-down(md){
                width: 12rem;
            }
            @include media-breakpoint-down(md){
                width: 15rem;
                height: 5rem;
            }
        }
        &-nsl {
            width: 7.75rem;
            background: url("images/nsl-logo.png") no-repeat center/contain;
        }
    }

    .modal-content {
        background-color: rgba(255, 245, 230);
        .btn:not([class*=" btn-outline-"]){
            &:hover{
                background-color: $primary!important;
                color:$secondary!important;
            }
        }
    }

    .modal-dialog {
        display: grid !important;
        display: -ms-grid !important;
        place-items: center;
        width: 80% !important;
        @include media-breakpoint-down(md){
            width: 90% !important;
        }
        max-width: none !important;
    }

    .btn-close {
        background: $primary;
        @include transition($nav-link-transition);
        &:hover {
            background: $premier;
        }
    }

    .modal-saltus-logo {
        width: 150px;
    }
}

#bjNotificationModal{
    .modal-body{
        .col-lg-5 span{
            @include media-breakpoint-up(lg){
                margin-bottom: 3.1rem!important;
            }
        }
    }
}

#faModal{
    .modal-content{
        max-width: $modal-md!important;
    }
}

.card-modal{
    .modal-dialog {
        display: flex!important;
    }
    .img-fluid{
        @include media-breakpoint-down(lg){
            max-width: none!important;
        }
    }
}

.modal-form{
    
    background-color: rgba($primary, .5)!important;
    backdrop-filter: blur(0px);
    z-index: 15000;
    //transition-delay: 2s;
    transition: backdrop-filter 0.3s ease-in-out;
    @include media-breakpoint-up(md){ 
    &.fade{
        opacity: 1!important;
        .modal-dialog{
            -webkit-transform: translate(-70%, 0px);
            -ms-transform: translate(-70%, 0px);
            transform: translate(-70%, 0px);
         
        }
    }
}
    &.show{
        backdrop-filter: blur(5px);
        .modal-dialog{
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
        }
    }
    .modal-body{
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .modal-content{
        
        @include media-breakpoint-up(md){ width: 70%!important;}
    }
    .btn-close {
        background: $primary;
        @include transition($nav-link-transition);
        &:hover {
            background: $premier;
        }
    }
   
    
}

.container-xl .modal-form .container-xl{
    padding-left: $grid-gutter-width!important;
    padding-right: $grid-gutter-width!important;
}