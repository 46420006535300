@import "../../variables";

@mixin mask {
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}

@mixin arrow {
    @include mask;
    -webkit-mask-image: url("images/right-arrow-premier.svg");
    mask-image: url("images/right-arrow-premier.svg");
}

// icon size
.icon {
    &-lg {
        height: 1.5rem !important;
        width: 1.5rem !important;
    }
}

.arrow-link {
    &::after {
        width: 1.5rem !important;
    }
    &.arrow-inline{
        display: inline-flex;
        &::after{
            margin-left: $spacer/2;
        }
    }
    &.btn{
        &:after{
            transition: transform 0.3s ease-in-out;
        }
        &:hover{
            &:after{
                transform: translatex(10px);
            }
        }
    }
}

.arrow-link, .plus-to-cross {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
        content: '';
        display: block;
        background: $secondary;
        height: $arrow-size;
        width: $arrow-size;
        flex-shrink: 0;
        @include arrow;
    }

    &-bigger {
        &::after {
            height: 1.25rem;
            width: 1.5rem;
        }
    }

    &-premier {
        &::after {
            background: $premier;
        }
    }

    &-private {
        &::after {
            background: $private;
        }
    }
    &-dark {
        &::after {
            background: $dark;
        }
    }
}

.icon-arrow-right {
    display: inline-block;
    background: $secondary;
    height: $arrow-size;
    width: 1.5rem;
    @include arrow;
    transition: transform 0.3s ease-in-out;
}

.icon-arrow-back{
    @include arrow;
    height: $back-size;
    width: $back-size;
    transform: rotate(180deg);
}
.service-link:hover {
    .icon-arrow-right {
        transform: translateX($spacer);
    }
}

.arrow-link-up {
    &::after {
        transition: transform 0.3s ease-in-out;
        -webkit-mask-image: url("images/right-arrow-premier.svg");
        mask-image: url("images/right-arrow-premier.svg");
        transform: rotate(-90deg)
    }
    &:hover {
        &::after {
            transform: translateY(-10px) rotate(-90deg);
        }
    }
}
.arrow-link-down {
    &::after {
        transition: transform 0.3s ease-in-out;
        -webkit-mask-image: url("images/right-arrow-premier.svg");
        mask-image: url("images/right-arrow-premier.svg");
        transform: rotate(90deg)
    }
}

// Play Button
.icon-play {
    @include transition($nav-link-transition);
    @include mask;
    -webkit-mask-image: url("images/play-button.svg");
    mask-image: url("images/play-button.svg");
    background: $secondary;
    height: 5rem;
    width: 5rem;
}
