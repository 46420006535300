@import "../../variables";

 
.accordion-item {
    border:none;
    cursor: pointer;
    h6 {
        height: 5rem;
        transition: all 0.3s ease-in-out;
        padding-left: 0px;
    }

    &:hover {
        h6 {
            padding-left: 10px;
        }
    }
}

.premier-on-hover {
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid $primary !important;
    &:hover {
        border-bottom-color: $premier !important;
    }
}

.faq-accordion-header {
    h6 {
        display: grid;
        display: -ms-grid;
        grid-template-columns: 1fr 0fr;
        -ms-grid-columns: 1fr 0fr;
        column-gap: 1rem;
    }

    .plus-to-cross {
        &::after {
            transition: all 0.3s ease-in-out;
            background: $primary;
            -webkit-mask-image: url("images/cross.svg");
            mask-image: url("images/cross.svg");
            // @extend .icon-lg;
        }
    }

    .plus-to-cross.collapsed {
        &::after {
            transition: all 0.3s ease-in-out;
            transform: rotate(45deg);
            background: $primary;
        }
    }

    .plus-to-cross:not(.collapsed) {
        &::after {
            transform: rotate(-90deg);
        }
    }

    &:hover {
        .plus-to-cross.collapsed {
            &::after {
                background: $premier;
            }
        }
    }
}