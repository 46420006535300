@import "../../variables";
.pull-quote{
    .pull-out-text{
        padding: 2.5rem 0 2.5rem 2.5rem!important;
        @include media-breakpoint-down(lg){
            padding: 1.875rem 1.25rem !important;
        }
    
    }
    .team-profile {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    &-text{
        z-index: 1;
    }
    .carousel{
        min-height: auto!important;
        z-index: 1;
    }
    .carousel-indicators{
        bottom:-2.5rem!important;
        @include media-breakpoint-down(lg){
            bottom: -1.875rem!important;
        }
    }
    .carousel-item{
        display: block!important;
        transition:visibility .2s ease-in-out, opacity .2s ease-in-out!important; 
            visibility:hidden!important; opacity:0!important;
        &.active{
            visibility:visible!important; opacity:1!important;
        }
    }
}