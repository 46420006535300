.location-panel{
    img{
        width: 75%;
        @include media-breakpoint-down(md) {
            width: 50%;
        }
        @include media-breakpoint-down(sm) {
            width: 75%;
        }
    }
    .ratio{
        @include media-breakpoint-up(md) {
            width: 100%;
            height: 100%;
            &::before{
                display: none;
            }
        }
    }
}