.ais-Hits-list, #algolia-pagination ul{
    list-style: none;
    padding:0;
    margin:0;
}

.ais-hits--content{
    mark{
        color:$premier!important;
        padding:0;
    }
}

#algolia-pagination{
    ul{
        margin-left:-$spacer;
        li{
            display: inline-block;
            vertical-align: middle;
            margin: 0 $spacer;
            a{
                text-decoration: none!important;
            }
        }
    }
    .ais-Pagination-item--selected{
        a{
            color:$premier!important;
            font-weight: bold;
        }
    }
    .ais-Pagination-item--disabled{
        opacity: 0.5;
    }
}

.ais-Pagination-item--firstPage a,.ais-Pagination-item--previousPage a, .ais-Pagination-item--nextPage a,.ais-Pagination-item--lastPage a,
.ais-Pagination-item--firstPage span,.ais-Pagination-item--previousPage span, .ais-Pagination-item--nextPage span,.ais-Pagination-item--lastPage span {
    color:$secondary!important;
    position: relative;
    height: $h4-font-size;
    width: $h4-font-size;
    display: block;
    &:hover{
        &::before {
            color:$premier!important;
        }
    }
    &::before{
        display: block;
        position: absolute;
        top:0;
        left: 0;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Pro"; 
        font-weight: 400; 
        font-size: $h5-font-size;
        line-height: $h4-font-size;
        color:$primary!important;
        width:100%;
        text-align: center;
    }
    
}

  .ais-Pagination-item--previousPage a::before,   .ais-Pagination-item--previousPage span::before {
    content: "\f053";
  }
  .ais-Pagination-item--firstPage a::before,  .ais-Pagination-item--firstPage span::before {
    content: "\f323";
  }
  .ais-Pagination-item--nextPage a::before,   .ais-Pagination-item--nextPage span::before {
    content: "\f054";
  }
  .ais-Pagination-item--lastPage a::before,  .ais-Pagination-item--lastPage span::before {
    content: "\f324";
  }



  #searchform{
    margin-bottom: 1px!important;
    width: 250px!important;
    // background-color: yellow;
    // height: 100%;
    .s-wrapper{
        border-left: 1px solid $secondary;
        @include media-breakpoint-down(lg){ // affects md and smaller
            border-left:0!important;
        }
        width: 25%;
        @media (min-width: 1500px){
            width: 30%;
            }
        @media (min-width: 1800px){
        width: 35%;
        }
        position: absolute;
        right:0;
        top: 0;
    }
    #s{
        padding:$spacer*0.75!important;
        padding-left: $spacer*2.25!important;
        border: none!important;
        display: block!important;
        border-radius: 0!important;
        background-color:$midblue;
        color:$secondary;
        background-image:  url('images/search-icon.svg');
        background-position: $spacer*0.75 $spacer;
        background-repeat: no-repeat;
        background-size: $font-size-base $font-size-base;
        width: 100%;
        &:focus{
            background-color:$secondary;
            background-image:  url('images/search-icon-active.svg');
            border: none!important;
            border-radius: 0!important;
            outline: none!important;
            color:$primary;
            &::placeholder {
                color: $primary;
                opacity: 1; /* Firefox */
              }
              
             & ::-ms-input-placeholder { /* Edge 12 -18 */
                color: $primary;
              }
        }
        &::placeholder {
            color: $secondary;
            opacity: 1; /* Firefox */
          }
          
         & ::-ms-input-placeholder { /* Edge 12 -18 */
            color: $secondary;
          }
        
    }

  }

  #mobile-nav{
    #s,.s-wrapper,#searchform{
        width: 100%!important;
    }
    .s-wrapper{
        position: relative;
    }
  }

  .aa-dropdown-menu{
    font-size: $font-size-base!important;
    font-weight: $font-weight-base!important;
    font-family: $font-size-base!important;
    color:$primary!important;
    background:$secondary!important;
    //width: 251px!important;
    margin-left: -1px!important;
    @extend .shadow;
    border-top: none!important;
    border-radius: 0 0 $border-radius $border-radius;
    padding:0 $spacer;
    em {
        font-style: normal;
        color:$premier;
    }
    margin-bottom: $spacer;
  }

//   .s-more-link:last-child{
//     background:blue!important;
// }


  .algolia-autocomplete {
	z-index: 999999 !important;
    //display: block!important;
   @include media-breakpoint-down(lg){ // affects md and smaller
    display: none!important;
   }

}

.aa-dropdown-menu:after {
	content: " ";
	display: block;
	clear: both;

}

.aa-dropdown-menu .aa-input, .aa-dropdown-menu .aa-hint {
	width: 100%;
}

/* Suggestion */
.aa-dropdown-menu .aa-suggestion{
    .h6{
        display: none!important;
    }
    &:last-child {
        .h6{
            display: block!important;
            &:hover{
                .line__dark{
                    border-color: $premier!important;
                }
            }
            +.h6{
                display: none!important;
            }
        }
        
    }
}

/* Footer */

.aa-dropdown-menu .autocomplete-footer-branding {
	color:$primary;
    padding:$spacer 0;
}

/* Clearfix */
.aa-dropdown-menu .clear {
	clear: both;
}

/* Empty */
.autocomplete-empty {
	clear: both;
	padding: $spacer 0;
}

.autocomplete-empty .empty-query {
	font-weight: bold;
}
