@import "../../variables";

$colwidth: 8.33333333%;

.col-title{
    margin-left:$colwidth;
    margin-right:$colwidth;
    @include media-breakpoint-down(lg){
        //background-color: yellow;
        margin-left:$colwidth*0.75;
        margin-right:$colwidth*0.75;
    }
    @include media-breakpoint-down(md){
        //background-color: red;
        margin-left:0;
        margin-right:0;
    }
}

.col-panel{
    margin-left:$colwidth;
    margin-right: $colwidth*0.75;
    flex: 0 0 auto;
    width: 35.4166666725%;
    @include media-breakpoint-down(lg){
        //background-color: yellow;
        margin-left:$colwidth*0.75;
        margin-right: $colwidth*0.5;
        width: 39.5833333375%;
    }
    @include media-breakpoint-down(md){
       // background-color: red;
        margin-left:0;
        margin-right: 0;
        flex: 1 0 100%!important;
        width: 100%!important;
    }
    .btn-primary{
        &:hover{
            background-color:$premier;
            border-color:$premier;
            color:$secondary;
        }
    }
    &:nth-child(even){
        margin-right:$colwidth;  
        margin-left: $colwidth*0.75;
        @include media-breakpoint-down(lg){
         //   background-color: yellow;
            margin-right:$colwidth*0.75;  
            margin-left: $colwidth*0.5;
        }
        @include media-breakpoint-down(md){
           // background-color: red;
            margin-left:0;
            margin-right: 0;
        }
        
    }
}