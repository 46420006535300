@import "../../../variables";

.pull-out {
    border-radius: 12px 0px 0px 12px !important;        
    right: 0;
    
    @include media-breakpoint-down(lg){
        padding: 1.875rem 1.25rem !important;
    }

    @include media-breakpoint-up(lg){
        border-radius: 200px 0px 0px 200px !important;
        top: 0;
        right: 0;
    }

    &-contact {
        @include media-breakpoint-down(lg){
            top: 0;
            left: 0;
        }
    }

    &-home {
        border-radius: 0 .75rem .75rem !important;
        @include media-breakpoint-up(lg){
            border-radius: 0 12.5rem 12.5rem 0!important;
        }
            @include media-breakpoint-down(lg){
                bottom: 0;
                
            }
           
        }
    &-text{
        padding: 3.75rem 0 3.75rem 5.313rem;
        position: relative;
        &:after{
            background: inherit;
            position: absolute;
            height: 100%;
            width: 2000px;
            top:0;
            left:99%;
            content: '';
            display: block;
            z-index: 0;
        }
    }
}

.wi-landing {
    scroll-margin-top: 13rem;

    &-chart{
        .col-md-3{
            position: absolute;
            top:-7rem;
            right: 0;
            width: 20%!important;
            font-size: $font-size-base*0.9;
            @include media-breakpoint-down(md){
                width: auto!important;
                position: relative;
                top:auto;
                right:auto;
            }
        }
        
        .chart-wrapper{
            min-width: auto!important;
        }
    }

    &-offset-top {
        height: 6.5625rem
    }

    &-hero {
        @include media-breakpoint-up(lg){
            height: 27.75rem;
            // height: calc(100vh - 105px - 70px); //minus height of header and sub menu
            // min-height: 27.75rem;
            // max-height: 800px; // to stop it getting massive
        }
        .hero-image {
            background:no-repeat bottom/contain;
            bottom: 0;
            right: 12px;
            max-width: 40rem;
            @include media-breakpoint-up(lg){
                position: absolute;
                // &-middle{
                //     top: 50%;
                //     bottom: 50%;
                //     margin: auto;
                // }
            }
            @include media-breakpoint-down(md){
                max-width: 100%!important;
            }
        }
    }

    .pull-out-container {

        position: relative;
        &:before{
            content: '';
            width: 10000px;
            right:50%;
            background:inherit;
            position: absolute;
            height: 100%;
            top:0;
            z-index: -1;

        }
    }

   

    .pull-out-chart {
        // left: 0;
        border-radius: 0 .75rem .75rem 0;
        padding: 3.75rem 5.313rem 3.75rem 0;
        @include media-breakpoint-up(lg){
            border-radius: 0 12.5rem 12.5rem 0;
        }

        .wi-chart-button {
            background-color: $premier;
            @include transition($nav-link-transition);
            &:hover {
                background-color: $secondary;
            }
            a {
                color: $secondary !important;
                &:hover {
                    color: $primary !important;
                }
            }
        }
    }

    &-overview {
        .pull-out {
            padding: 3.75rem 5.313rem 3.75rem 0
        }
    }

    &-media {
        .pull-out {
            padding: 3.125rem 5.313rem;
            p, h4 {
                color: $secondary;
                margin-bottom: 0;
            }
            a {
                color: $premier;
                margin-bottom: 0;
            }
        }

        &-link {
            color: $primary !important;
            h3 {
                @include transition($nav-link-transition);
            }
            &:hover {
                h3 {
                    color: $premier !important;
                }
            }

            &.arrow-link {
                @include media-breakpoint-down(lg){
                    align-items: start !important;
                }
            }
        }
    }

    &-experts {
        //overflow: hidden;

        .position-sticky{
            top:180px!important;
        }

        &:not(.experts-spp){

        .accordion-header {
            @include media-breakpoint-down(xl){
                width: 105%;
            }
            .header-container {
                @include media-breakpoint-down(xl){
                    width: 100%;
                }
            }

            .rounded-pill{

                &.collapsed {
                    @include media-breakpoint-up(xl){
                        &.accordion-header-0 {
                            &:hover {
                                background-color: $premier;
                            }
                        }
                        &.accordion-header-1 {
                            &:hover {
                                background-color: $private;
                            }
                        }
                        &.accordion-header-2 {
                            &:hover {
                                background-color: $lorica;
                            }
                        }
                    }
                }
                @include media-breakpoint-up(xl) {
                 
                    &:hover{
                        .arrow-link:after{
                            transform: translateX(10px) rotate(0deg);
                        }
                    }
                }
                &.accordion-header-0, &.accordion-header-1, &.accordion-header-2 {
                    @include media-breakpoint-down(xl){
                        margin-right: -1rem;
                    }
                    @include media-breakpoint-down(md){
                        margin-right: -2rem;
                    }
                    &:not(.collapsed) {
                        @include media-breakpoint-down(xl){
                            margin-right: -1rem;
                        }
                        @include media-breakpoint-down(md){
                            margin-right: -2rem;
                        }
                        @include media-breakpoint-up(xl){
                            background-color: $premier;
                        }
                    }
                }
                &.accordion-header-0{

                    &:not(.collapsed) {
                        @include media-breakpoint-up(xl){
                            background-color: $premier;
                        }
                    }
                }

                &.accordion-header-1 {
     
                    &:not(.collapsed) {
                        @include media-breakpoint-up(xl){
                            background-color: $private;
                        }
                    }
                }
                &.accordion-header-2 {
                    &:not(.collapsed) {
                        @include media-breakpoint-up(xl){
                            background-color: $lorica;

                        }
                    }
                }

                @include media-breakpoint-down(xl){
                    border-radius: 10px 0 0 10px !important;
                }

                @include media-breakpoint-down(xl){
                    &.collapsed {
                        .arrow-link-up::after {
                           
                            transform: rotate(90deg);
                        }
                    }
                    &:not(.collapsed) {
                        .arrow-link-up::after {
                     
                            transform: rotate(-90deg);
                        }
                    }
                }

            
            }
        }
        .arrow-link {
            align-items: start !important;
            @include media-breakpoint-up(xl) {
                &:after{
                    width: $arrow-size*1.75!important;
                    height: $arrow-size*1.25!important;
                    transition: transform 0.3s ease-in-out;
                }
             
            }
            
            @include media-breakpoint-down(lg) {
                p {
                    margin-bottom: 1rem !important;
                }
            }
        }

       
        }

        &.experts-spp{

            .accordion-header {
                @include media-breakpoint-down(lg){
                    width: 105%;
                }
                .header-container {
                    @include media-breakpoint-down(xl){
                        width: 100%;
                    }
                }
    
                .rounded-pill{
    
                    &.collapsed {
                        @include media-breakpoint-up(lg){
                            &.accordion-header-0 {
                                &:hover {
                                    background-color: $premier;
                                }
                            }
                            &.accordion-header-1 {
                                &:hover {
                                    background-color: $private;
                                }
                            }
                            &.accordion-header-2 {
                                &:hover {
                                    background-color: $lorica;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-up(xl) {
                 
                        &:hover{
                            .arrow-link:after{
                                transform: translateX(10px) rotate(0deg);
                            }
                        }
                    }
    
                    &.accordion-header-0, &.accordion-header-1, &.accordion-header-2 {
                        @include media-breakpoint-down(lg){
                            margin-right: -1rem;
                        }
                        @include media-breakpoint-down(md){
                            margin-right: -2rem;
                        }
                        &:not(.collapsed) {
                            @include media-breakpoint-down(lg){
                                margin-right: -1rem;
                            }
                            @include media-breakpoint-down(md){
                                margin-right: -2rem;
                            }
                            @include media-breakpoint-up(lg){
                                background-color: $premier;
                            }
                        }
                    }
                    &.accordion-header-0{
    
                        &:not(.collapsed) {
                            @include media-breakpoint-up(lg){
                                background-color: $premier;
                            }
                        }
                    }
    
                    &.accordion-header-1 {
         
                        &:not(.collapsed) {
                            @include media-breakpoint-up(lg){
                                background-color: $private;
                            }
                        }
                    }
                    &.accordion-header-2 {
                        &:not(.collapsed) {
                            @include media-breakpoint-up(lg){
                                background-color: $lorica;
    
                            }
                        }
                    }
    
                    @include media-breakpoint-down(lg){
                        border-radius: 10px 0 0 10px !important;
                    }

                    @include media-breakpoint-down(lg){
                        &.collapsed {
                            .arrow-link-up::after {
                               
                                transform: rotate(90deg);
                            }
                        }
                        &:not(.collapsed) {
                            .arrow-link-up::after {
                         
                                transform: rotate(-90deg);
                            }
                        }
                    }
                
                }
            }

            .arrow-link {
                align-items: start !important;
                @include media-breakpoint-up(lg) {
                    &:after{
                        width: $arrow-size*1.75!important;
                        height: $arrow-size*1.25!important;
                        transition: transform 0.3s ease-in-out;
                    }
                 
                }
                @include media-breakpoint-down(lg) {
                    p {
                        margin-bottom: 1rem !important;
                    }
                }
            }

       
            }

        .team-profile {
            width: 9rem;
            margin: 0 !important;
            &-inner {
                height: 9rem !important;
                width: 9rem !important;
            }
             &-bg {
                left: 1px;
             }
        }

        .accordion-header {
            .header-container {
                height: 144px;
                // width: 24rem;
                border-radius: 50rem;
                z-index: -1;
                transition: all .15s ease-in-out;

                &:hover {
                    // width: 109%;
                    // margin-left: -2.2rem;
                    &:has(.accordion-header-0) {
                        background-color: $premier !important;
                    }
                    &:has(.accordion-header-1) {
                        background-color: $private !important;
                    }
                    &:has(.accordion-header-2) {
                        background-color: $lorica !important;
                    }
                }
            }

            .rounded-pill{

                &.collapsed {
                    background-color: $tertiary;
                    @include transition($nav-link-transition);

                    
                }

                &.accordion-header-0 {
                   
                    &:not(.collapsed) {
                        background-color: $tertiary;
                        @include transition($nav-link-transition);
                        
                    }
                }

                &.accordion-header-1 {
                   
                    @include transition($nav-link-transition);
                    &:not(.collapsed) {
                        background-color: $tertiary;
                       
                    }
                }
                &.accordion-header-2 {
                   
                    &:not(.collapsed) {
                        background-color: $tertiary;
                        @include transition($nav-link-transition);
                       
                    }
                }

                &.collapsed {
                    .arrow-link-up::after {
                       
                        transform: rotate(0deg);
                    }
                }
                &:not(.collapsed) {
                    .arrow-link-up::after {
                 
                        transform: rotate(0deg);
                    }
                }

                
                .team-profile-bg {
                    circle {
                        fill: $primary;
                    }
                }
                
            }

           

            
        }
    }
    
    &-sticky {
        position: sticky;
        top: 121px; //123px previously
        z-index: 10;
        background-color: $midblue;
        &.js-is-sticky{
            .wi-link-active{
                &:after{
                    opacity: 0!important;
                }
            }
        }
        &-link {
            .nav-link {
                font-size: 1.125rem !important;
                @extend .h5;
                color: $secondary;
                @include transition($nav-link-transition);
                &.active {
                    color: $premier;
                }
                &:after{
                    transition: opacity 0.3s ease-in-out;
                    opacity: 0;
                }
            }
            .wi-link-active{
                position: relative;
                &:after{
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 12px solid rgba(0,0,0,0);
                    border-right: 12px solid rgba(0,0,0,0);
                    border-bottom: 12px solid $secondary;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    bottom:-$spacer*1.9;
                    opacity: 1;
                    
                }
            }
        }

        &-mobile {
            position: sticky;
            top: 74px;
            z-index: 11;
        }
    }

    &-circle {
        // height: 25rem;
        // width: 25rem;
        // @include media-breakpoint-down(sm){
        //     height: 23.375rem;
        //     width: 23.375rem;
        // }
        position: relative;
        display: block;
        margin: 2em 0;
        background-color: transparent;
        color: #222;
        text-align: center;
        max-width: 25rem;
        &:after {
            display: block;
            padding-bottom: 100%;
            width: 100%;
            height: 0;
            border-radius: 50%;
            content: "";
        }
        
        
        .circle-inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &-link {
                @include transition($nav-link-transition);
                background-color: $premier;
                color: $secondary !important;
                &:hover {
                    background-color: $secondary !important;
                    color: $primary !important;
                }
            }
        }
        
        .circle-wrapper {
            display: table;
            width: 100%;
            height: 100%;



            .arrow-link::after {
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                .arrow-link::after {
                    transform: translateX(5px);
                }
            }
        }
        
        .circle-content {
            display: table-cell;
            padding: 1em;
            vertical-align: middle;
        }
        
        .line__short {
            width: 13% !important;
        }
    }

    &-bg {
        height: 10rem;
        right: 0;
        left: 0;
        z-index: -1;
    }

    .wealth-index-sticky{
        .mt-n5{
            margin-top: -3.1em!important;
        }
        @include media-breakpoint-down(lg){
            top: 153px !important;
        }
    }

    .wi-chart-tip{
        @include media-breakpoint-down(md){
         max-width: 33%;
        }
        @include media-breakpoint-down(sm){
            max-width: 50%;
           }
           @media (max-width: 400px) {
            max-width: 80%;
           }
        margin:0 auto;
    }
    @include media-breakpoint-down(lg){
        > .container-xl, > .container-xl > .row > .col-lg-3, > .container-xl > .row > .col-lg-2{
            padding-left: 0!important;
            padding-right: 0!important;
        }
        > .container-xl > .row > .col-lg-9, > .container-xl > .row > .col-lg-10{
            padding-left: $grid-gutter-width!important;
            padding-right: $grid-gutter-width!important;
        }
        > .container-xl > .row{
            margin-left: 0!important;
            margin-right: 0!important;
        }
    }
}

.sub-menu-true{
    .wealth-index-sticky{
        @include media-breakpoint-down(lg){
            top: 200px !important;
        }
    }
}

.wi-landing-chart{
    .chart-wrapper{
        .chart{
            min-width: auto!important;
        }
        @include media-breakpoint-down(sm){
            .chart{
                min-width: 640px!important;
            }
        }
    }
}


.premier{
    
    .wi-landing-sticky-link .nav-link {
        &.active,&:hover {
            color: $premier!important;
        }
    }
    
}

.gold{
    
    .wealth-index-sticky-mobile .accordion-button:not(.text-dust)::after{
        background: $primary!important;
    }
    

    .wi-landing-sticky-link .nav-link {
        &.active,&:hover {
            color: $gold!important;
        }
    }
    .wi-landing-sticky{
        top: 73px !important;
    }

    .wi-landing {
        &-hero {
            .spp-hero-image {
                width: 38%;
            }
        }
        &-experts {
            .accordion-header {
                .header-container {
                    &:hover {
                        .rounded-pill {
                        background-color: $gold!important;
                        color:$primary!important;
                        .arrow-link:after{
                            background:$primary!important;
                        }
                        
                        }
                    }
                }
                .rounded-pill{
                    
                  
                        &:not(.collapsed) {
                            background-color: $gold!important;color:$primary!important;
                            .arrow-link:after{
                                background:$primary!important;
                            }
                            
                        }
                        
                    
                    &.collapsed {
                        @include media-breakpoint-up(xl){
                            
                                &:hover {
                                    background-color: $gold!important;
                                    color:$primary!important;
                                    .arrow-link:after{
                                        background:$primary!important;
                                    }
                                }
                            
                            
                        }
                    }
                }
            }
            &.experts-spp{
                .rounded-pill{
                    &.collapsed {
                        @include media-breakpoint-up(lg){
                           
                                &:hover {
                                    background-color: $gold!important;
                                    color:$primary!important;
                                }
                            
                        }
                    }
                }
            }
        }
    }
    
}

.private{

    .wi-landing {
        &-experts {
            .accordion-header {
                .header-container {
                    &:hover {
                        .rounded-pill {
                        background-color: $private!important;
                        color:$white!important;
                        .arrow-link:after{
                            background:$white!important;
                        }
                        
                        }
                    }
                }
                .rounded-pill{
                    
                  
                        &:not(.collapsed) {
                            background-color: $private!important;color:$white!important;
                            .arrow-link:after{
                                background:$white!important;
                            }
                            
                        }
                        
                    
                    &.collapsed {
                        @include media-breakpoint-up(xl){
                            
                                &:hover {
                                    background-color: $private!important;
                                    color:$white!important;
                                    .arrow-link:after{
                                        background:$white!important;
                                    }
                                }
                            
                            
                        }
                    }
                }
            }
        }
    }
    
    .wi-landing-sticky-link .nav-link {
        &.active,&:hover {
            color: $private!important;
        }
    }
    
}

.asset{

    .wi-landing {
        &-experts {
            .accordion-header {
                .header-container {
                    &:hover {
                        .rounded-pill {
                        background-color: $asset!important;
                        color:$white!important;
                        .arrow-link:after{
                            background:$white!important;
                        }
                        
                        }
                    }
                }
                .rounded-pill{
                    
                  
                        &:not(.collapsed) {
                            background-color: $asset!important;color:$white!important;
                            .arrow-link:after{
                                background:$white!important;
                            }
                            
                        }
                        
                    
                    &.collapsed {
                        @include media-breakpoint-up(xl){
                            
                                &:hover {
                                    background-color: $asset!important;
                                    color:$white!important;
                                    .arrow-link:after{
                                        background:$white!important;
                                    }
                                }
                            
                            
                        }
                    }
                }
            }
        }
    }
    
    .wi-landing-sticky-link .nav-link {
        &.active,&:hover {
            color: $asset!important;
        }
    }
    
}

.lorica{
    
    .wi-landing-sticky-link .nav-link {
        &.active,&:hover {
            color: $lorica!important;
        }
    }
    
}

.side-menu-true{
    @include media-breakpoint-up(lg){
    margin-top:-$spacer*3;
    .wealth-index-sticky{
        top: 140px !important;
    }
    .cta_full{
        width: 83.33333333%;
    }
    }
    .explainer-container{
        .align-items-lg-center{
            align-items: start!important;
        }
        .animate-line{
            top:18%!important;
        }
    }

}

