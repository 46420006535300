@use "sass:color";
@import "../variables";

.spinner{
    animation: rotation .6s linear infinite;
    border-radius: 100%;
    display: none;
    height: 1rem;
    margin-left: .5rem;
    width: 1rem;
    z-index: 10000;
    margin-top: -2px;
}
.spinner-large {
    width: 2rem;
    height: 2rem;
}

@each $color, $value in $brand-colors {
    .spinner-#{$color} {
        border: 2px solid color.adjust($value, $alpha: -0.5);
        border-top-color: color.adjust($value, $alpha: -0.9);
    }
}

button span {
    vertical-align: middle;
}


button:disabled .spinner {
    display: inline-block;
}

@keyframes rotation{
    0%{
        transform: rotate(0deg)
    }
    to{
        transform: rotate(359deg)
    }
}


/*
<div class="bg"><div class="spinner"></div></div>
*/